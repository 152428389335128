import { Component } from '@angular/core';
import {CDN_URL} from './app.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pdf-frontend';
  cdnURL = CDN_URL
  data: any = JSON.parse(sessionStorage.getItem('pdfData'));
  hubbleData: any = JSON.parse(sessionStorage.getItem('hubblePDF'));

}
