export let COLORS = {
  root: {
    basic: {
      color1: '#0b2d4e',
      color2: '#2d77ce',
      color3: '#e34a5c',
      color4: '#525252',
      color5: '#e9f2fe',
      color6: '#656565',
      color7: 'steelblue',
      color8: '#339900',
    },
    'LG-Solar' : {
      color1: '#333333',
      color2: '#A50034',
      color3: '#666666',
      color4: '#EEEEEE',
      color5: '#666666',
      color6: '#666666',
      color7: '#A50034',
      color8: '#339900'
    },
    'Enervee-SCE' : {
      color1: '#00664F',
      color2: '#F5A54C',
      color3: '#DD2200',
      color4: '#EEEEEE',
      color5: '#666666',
      color6: '#656565',
      color7: '#F5A54C',
      color8: '#339900'
    },
    'Enervee-coned': {
      color1: '#113355',
      color2: '#069BD7',
      color3: '#DD2200',
      color4: '#EEEEEE',
      color5: '#666666',
      color6: '#666666',
      color7: '#069BD7',
      color8: '#339900'
    },
    'Solar Com' : {
      color1: '#896fda',
      color2: '#f57a74',
      color3: '#DD2200',
      color4: '#EEEEEE',
      color5: '#666666',
      color6: '#666666',
      color7: '#f57a74'
    }
  }
};

