export let LANG = {
  main: {
    'LG-Solar' : {
      bid: 'offer',
      Bid: 'Offer',
      brand_logo : 'https://assets.solar.com/partner_assets/lg/lg-logo.png',
      footer_logo: 'https://assets.solar.com/partner_assets/lg/lg-logo.png',
      battery_logo : 'https://assets.solar.com/lg-pdf/lg-battery.png',
      partner : 'LG Solar',
      consumption: '#447700',
      production : '#'
    },
    'Solar Com' : {
      brand_logo : 'https://assets.solar.com/partner_assets/lg/solar-logo-c.svg',
      footer_logo: 'https://assets.solar.com/partner_assets/lg/solar-logo-w.svg',
      partner : 'Solar'
    },
    'Pick My Solar' : {
      brand_logo : 'https://assets.solar.com/partner_assets/lg/logo.png',
      footer_logo: 'https://assets.solar.com/partner_assets/lg/logo.png',
      partner : 'Pick My Solar'
    },
    default : {
      bid: 'bid',
      Bid : 'Bid',
      brand_logo : 'https://assets.solar.com/partner_assets/lg/electrum-logo.svg',
      footer_logo: 'https://assets.solar.com/partner_assets/lg/electrum-logo-white.svg',
      battery_logo : 'https://assets.solar.com/lg-pdf/battery.png',
      partner : 'Electrum',
      srec: 'https://assets.solar.com/lg-pdf/srec-image.png'
    }
  },
  data: {
    text: {}
  }
}
