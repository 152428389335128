<div class="pdf-output" *ngIf="data">
  <section class="pdf-intro">
    <div class="pdf-title">
      <div class="header-box">
        <i class="pms-icon title-icon icon-battery-mainhead" title="Icon - battery" label="icon"></i>
        <h1>Your Home Battery Offer</h1>
        <span class="button-ca">{{data.isLoan ? 'Loan' : 'Cash' | titlecase}}</span>
        <address>
          <span>{{data.user.userName}}</span><br>
          <div *ngIf="data.user.streetAddress" class="street-address">
            {{data.user.streetAddress}},<br>
            {{data.user.city?data.user.city+', ':''}}{{data.user.state?data.user.state+' ':''}}{{data.user.zip?data.user.zip:''}}
          </div>
          <div *ngIf="!data.user.streetAddress">
            {{data.user.address}}
          </div><br>
        </address>
        <img [src]="'brand_logo'| lang: componentLocation" alt="Brand logo image" class="logo-image" />
      </div>
    </div>
    <img [src]="'battery_logo' | lang: componentLocation" alt="Battery image" class="logo-image img-1">
  </section>
  <div class="pdf-output">
    <section *ngFor="let battery of data.batteryBids;let i = index">
      <div class="pdf-data">
        <div class="system-details-title">
          <div class="system-details-header">
            <div class="d-flex">
              <h1>Your Offers</h1> <span class="button-ca" style="margin:auto 1em">{{data.isLoan ? 'Loan' : 'Cash' | titlecase}}</span>
            </div>
          </div>
          <div class="system-details-title-info">
            <h1>{{'Bid' | lang: componentLocation}} {{i+1}}</h1>
            <div class="bid-type-title">
              <i class="pms-icon icon-batttery"></i>
              <h3>Battery</h3>
            </div>
          </div>
        </div>
        <hr class="dark-hr" />
        <div class="row">
          <div class="col-sm-3 col-md-3 col-lg-3" style="margin:auto">
            <img [src]="battery.data.lineItems.eqp_battery.product_image" alt="Battery image" style="width:100%; max-width:150px; height:150px">
          </div>
          <div class="col-sm-9 col-md-9 col-lg-9" style="margin:auto">
            <div *ngIf="data.isLoan" class="battery-detail">
              <h2 class="battery_name">{{battery.data.lineItems.eqp_battery.display_name}}</h2>
              <h3 class="battery_cost">{{battery.pricing_data.LoanOption.LoanData.averagePayment | currency:'USD':'symbol':'2.0'}}</h3>
              <h3 class="payment">Monthly Payment</h3>
            </div>
            <div *ngIf="!data.isLoan" class="battery-detail">
              <h2 class="battery_name">{{battery.data.lineItems.eqp_battery.display_name}}</h2>
              <h3 class="battery_cost">{{battery.pricing_data.netPrice | currency : 'USD' : 'symbol' : '1.0'}}</h3>
              <h3 class="payment">Net Cost</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row pdf-content">
        <div class="col-sm-4 col-md-4 col-lg-4">

          <h2 class="title">Overview</h2>
          <hr>
          <div class="breakdown-list">
            <div class="first-item">
              <h3>Gross Price</h3>
              <h4 class="financials-price">{{battery.pricing_data.grossPrice | currency : 'USD' : 'symbol' : '1.0' }}</h4>
            </div>
            <!-- Pre rebates breakup -->
            <div *ngIf="battery?.pricing_data?.preRebates > 0">
              <div *ngFor="let reb of objectKeys(battery?.pricing_data?.preRebatesBreakUp)">
                <div *ngFor="let incentive of battery.pricing_data?.preRebatesBreakUp[reb]?.incentives">
                  <div class="gross-price dot-cont pl-1">
                    <h4>{{incentive.display_name}}</h4>
                    <h4 class="financials-price">
                      {{-incentive.calculatedValue | currency : 'USD' : 'symbol' : '1.0' }}</h4>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div *ngIf="battery.battery_incentives.utility_rebate_total_value">
              <h4>Utility Rebates</h4>
              <h4 class="financials-price">
                {{-battery.battery_incentives.utility_rebate_total_value | currency : 'USD' : 'symbol' : '1.0' }}</h4>
            </div> -->
            <div>
              <h4>{{data.isLoan ? 'Loan' : 'Cash' | titlecase}} Discount</h4>
              <h4 class="financials-price">{{-battery.pricing_data.cashDiscount | currency : 'USD' : 'symbol' : '1.0' }}</h4>
            </div>

            <!-- <div *ngIf="battery.incentives.state_rebate_total_value">
              <h4>{{battery.incentives.state_rebates.name}}</h4>
              <h4 class="financials-price">
                {{-battery.incentives.state_rebates.value | currency : 'USD' : 'symbol' : '1.0' }}</h4>
            </div> -->
            <div class="first-item contract-price">
              <h3>Contract Price</h3>
              <h4 class="financials-price">{{battery.pricing_data.contractPrice | currency : 'USD' : 'symbol' : '1.0' }}</h4>
              <!--<h4 *ngIf="bi.discounts && bi.discounts.totalDiscount" class="financials-price">{{bi.gross_price - bi.cash_discount - bi.discounts.totalDiscount | currency : 'USD' : 'symbol' :'1.0'}}</h4>-->
              <!--<h4 *ngIf="!bi.discounts.totalDiscount" class="financials-price">{{bi.gross_price - bi.cash_discount | currency : 'USD' : 'symbol' :'1.0'}}</h4>-->
            </div>
            <!-- Post rebates breakup -->
            <div *ngIf="battery?.pricing_data?.postRebates > 0">
              <div *ngFor="let reb of objectKeys(battery?.pricing_data?.postRebatesBreakUp)">
                <div *ngFor="let incentive of battery.pricing_data?.postRebatesBreakUp[reb]?.incentives">
                  <div class="gross-price dot-cont pl-1">
                    <h4>{{incentive.display_name}}</h4>
                    <h4 class="financials-price">
                      {{-incentive.calculatedValue | currency : 'USD' : 'symbol' : '1.0' }}</h4>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div *ngIf="battery.incentives.utility_rebate_total_value">
              <h4>Utility Rebates</h4>
              <h4 class="financials-price">
                {{-battery.incentives.utility_rebate_total_value | currency : 'USD' : 'symbol' : '1.0' }}</h4>
            </div>

            <div *ngIf="battery.incentives.federal_tax_credit_total_value">
              <h4>Federal Tax Credit</h4>
              <h4 class="financials-price">
                {{-battery.incentives.federal_tax_credit_total_value | currency : 'USD' : 'symbol' : '1.0' }}</h4>
            </div>
            <div *ngFor="let tax of battery.incentives.state_tax_credits">
              <h4>{{tax.name}}</h4>
              <h4 class="financials-price">
                {{-tax.value | currency : 'USD' : 'symbol' : '1.0' }}</h4>
            </div> -->
            <hr>
            <div class="first-item">
              <h3>Net Price</h3>
              <h4>{{battery.pricing_data.netPrice | currency : 'USD' : 'symbol' : '1.0' }}</h4>
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <h2 class="title">Specifications</h2>
          <hr>
          <div class="list">
            <h5 class="list-data">{{battery.extra_data?.battery_saving_settings?.batteryCapacity}} kWh(5kW)</h5>
            <h4 class="list-title">Capacity (Power)</h4>
            <a class='view-spec' href="#" aria-label="View full specs link" title="View full specs link">View full specs</a>
          </div>
          <div *ngIf="data.isLoan">
            <h2 class="title">Warranty</h2>
            <hr>
            <div class="list">
              <h5 class="list-data">{{battery?.data?.lineItems?.eqp_battery?.product_attributes?.display?.manufacturer_warranty}} Years / {{battery?.data?.lineItems?.eqp_battery?.product_attributes?.display?.capacity_warranty}}% Capacity</h5>
              <h4 class="list-title">Loan Provider</h4>
            </div>
            <div class="list">
              <h5 class="list-data">{{battery?.data?.lineItems?.eqp_battery?.manufacturer_warranty}} Years (Parts & Labor)</h5>
              <h4 class="list-title">{{battery.data.lineItems.eqp_battery.manufacturer_name}}</h4>
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div *ngIf="!data.isLoan">
            <h2 class="title">Warranty</h2>
            <hr>
            <div class="list">
              <h5 class="list-data">{{battery?.data?.lineItems?.eqp_battery?.product_attributes?.display?.manufacturer_warranty}} Years / {{battery?.data?.lineItems?.eqp_battery?.product_attributes?.display?.capacity_warranty}}% Capacity</h5>
              <h4 class="list-title">Loan Provider</h4>
            </div>
            <div class="list">
              <h5 class="list-data">{{battery?.data?.lineItems?.eqp_battery?.manufacturer_warranty}} Years (Parts & Labor)</h5>
              <h4 class="list-title">{{battery.data.lineItems.eqp_battery.manufacturer_name}}</h4>
            </div>
          </div>
          <div *ngIf="data.isLoan">
            <h2 class="title">Financing</h2>
            <hr>
            <div *ngIf="battery?.pricing_data?.LoanOption.displayName" class="list">
              <h5 class="list-data">{{battery?.pricing_data?.LoanOption.displayName}}</h5>
              <h4 class="list-title">({{battery.pricing_data?.LoanOption?.apr*100 | number : '1.0-2'}}% for {{battery.pricing_data?.LoanOption?.loanTerm/12 | number : '1.0-0'}} Years)</h4>
              <h4 class="list-title">Loan Provider</h4>
            </div>

            <div *ngIf="battery?.pricing_data?.LoanOption?.LoanData.payment1" class="list">
              <h5 class="list-data">{{battery?.pricing_data?.LoanOption?.LoanData.payment1 | currency : 'USD' : 'symbol'
                : '1.0'}} /Month</h5>
              <h4 class="list-title">Year 1 payments</h4>
            </div>
            <div *ngIf="battery?.pricing_data?.LoanOption?.LoanData.payment1" class="list">
              <h5 class="list-data">{{battery?.pricing_data?.LoanOption?.LoanData.payment2 | currency : 'USD' : 'symbol'
                : '1.0'}} /Month</h5>
              <h4 class="list-title">Year 2-10 payments</h4>
            </div>
            <div *ngIf="battery?.pricing_data?.LoanOption?.LoanData.averagePayment" class="list">
              <h5 class="list-data">{{battery?.pricing_data?.LoanOption?.LoanData.averagePayment | currency : 'USD' : 'symbol'
                : '1.0'}} /Month</h5>
              <h4 class="list-title">Average Loan Payment</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="installer-view" style="margin-top:40px;">
        <h2 class="title">Installer</h2>
        <div class="row">
          <div class="col-sm-3 col-md-3 col-lg-3">
            <div class="installer-image-div" style="max-width:120px; margin:auto">
              <img [src]="battery.extra_data.installer.logo_url" alt="Battery installer logo image" class="installer-image">
            </div>
            <div class="installer-title">
              <h4>{{battery.extra_data.installer.installer_name}}</h4>
            </div>
            <div class="rating-installers" style="max-width:120px; margin:auto">
              <img *ngIf="battery.extra_data.installer.review_images[0]" [src]="battery.extra_data.installer.review_images[0]" alt="Battery installer rating image" class="installer-image">
            </div>
          </div>
          <div class="col-sm-7 col-md-9 col-lg-9 installer-about">
            <h1>About</h1>
            <p *ngIf="battery.extra_data.installer.about">{{ battery.extra_data.installer.about | slice: 0:500 }}{{battery.extra_data.installer.about > 500 ? '...view more detail on dashboard.' : ''}}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!--footer-->
  <div class="foot"  [ngClass]="{'foot-lg' : data.partner_type =='LG-Solar'}">
    <p>Contact <span>{{data.manager.name | titlecase}}</span></p>
    <div class="d-flex align-items-center flex-direction-row">
      <i class="footer-icon icon-call"></i>
      <p>{{data.manager.phone}}</p>
    </div>
    <div class="d-flex align-items-center flex-direction-row">
      <i class="footer-icon icon-mail"></i>
      <p>{{data.manager.email}}</p>
    </div>
    <img [src]="'footer_logo'| lang: componentLocation" alt="Brand logo" class="foot-logo" style="width: 72px;height: 36px;">

</div>

</div>
