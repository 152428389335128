import { Component, OnInit , AfterViewInit} from '@angular/core';
import {CDN_URL} from '../app.constants';
import {AppService} from '../app.service';
import * as Highcharts from 'highcharts';
import '../shared/highcharts-plugin';

@Component({
  selector: 'app-solar-pdf',
  templateUrl: './solar-pdf.component.html',
  styleUrls: ['./solar-pdf.component.scss']
})
export class SolarPdfComponent implements OnInit, AfterViewInit {
  cdnURL = CDN_URL;
  data: any;
  public componentLocation = 'text';
  color1: any;
  color2: any;
  constructor(public appService: AppService) { }

  ngOnInit(): void {
    this.data = JSON.parse(sessionStorage.getItem('pdfData'));
    if (this.data.hasOwnProperty('color')){
      this.appService.setProps(this.data.color);
    } else {
      this.appService.setColors(this.data.partner_type);
    }
    this.appService.setLanguage(this.data.partner_type);
  }

  graphInit = bid => {
    this.color1 = getComputedStyle(document.documentElement)
      .getPropertyValue('--color-1');
    this.color2 = getComputedStyle(document.documentElement)
      .getPropertyValue('--color-2');
    const productionData = [];
    Object.keys(this.data.sysDetails.productionData[bid.bid_id]).map(r => {
      productionData.push(this.data.sysDetails.productionData[bid.bid_id][r]);
    });
    const consumptionData = this.data.sysDetails.consumptionData;
    Highcharts.chart('system-details-chart' + bid.bid_rank , {
      chart: {
        // renderTo : 'system-details-chart' + bid.bid_rank,
        type: 'column',
        animation: false
      },
      legend: {
        enabled: false
      },
      title: {
        text: '',
        align: 'left',
        x: 2,
        y: 5
      },
      xAxis: {
        categories: [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ],
        crosshair: true
      },
      yAxis: {
        // min: 0,
        title: {
          text: 'kWh',
          style: {
            color: this.color1,
            fontSize: '15px'
          }
        }
      },
      plotOptions: {
        column: {
          groupPadding: 0.08,
          borderWidth: 0
        }
      },
      colors: [this.color1, this.color2],
      series: [
        {
          type: 'column',
          name: 'Consumption',
          data: consumptionData,
          animation: false
        },
        {
          type: 'column',
          name: 'Production',
          data: productionData,
          animation: false
        }
      ],
      credits: {
        enabled: false
      }
    });
  };
  getAbsoluteVal = input => Math.abs(input);
  displayGraph = bid => {
    const annualSaving = bid.genability_data.annual_saving;
    const cumulativeSaving = bid.genability_data.cumulative_saving;
    Highcharts.chart( {
      chart: {
        renderTo : 'line-container' + bid.bid_rank,
        // alignThresholds: true,
        animation: false
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false,
        itemStyle: {
          display: 'none'
        }
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: [
        {
          title: {
            text: 'Year',
            style: {
              color: this.color1
            },
            margin: 10
          },
          tickInterval: 5,
          crosshair: true
        }
      ],
      yAxis: [
        {
          gridLineWidth: 0,
          title: {
            text: 'Cumulative Savings',
            style: {
              color: this.color1
            }
          },
          min: Math.min(...cumulativeSaving) - 1,
          max: Math.max(...cumulativeSaving)
        },
        {
          title: {
            text: 'Annual Savings',
            style: {
              color: this.color1
            }
          },
          min: Math.min(...annualSaving) - 1,
          max: Math.max(...annualSaving),
          opposite: true
        }
      ],
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false
            }
          },
          cursor: 'move',
          marker: {
            enabled: false
          }
        },
        spline: {
          lineWidth: 6
        },
        column: {
          pointWidth: 10,
          states: {
            hover: {
              color: '#0071bc'
            }
          }
        }
      },
      series: [
        {
          name: 'Annual Savings',
          type: 'column',
          color: '#d5edf9',
          data: annualSaving,
          yAxis: 1,
          animation: false
        },
        {
          name: 'Cumulative Savings',
          type: 'spline',
          data: cumulativeSaving,
          animation: false,
          negativeColor: '#ea2c22',
          color: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [[0, '#4dbe53'], [1, '#f5f23a']]
          }
        }
      ]
    });
  }

  Nem3(saving) {
    const annual_saving = saving.genability_data.annual_saving;
    const CumulativeSaving = saving.genability_data.cumulative_saving;
    const postSolar = saving.genability_data.postSolar;
    const preSolar = saving.genability_data.preSolar;
    for (let i = 0; i < saving.genability_data.postSolar.length; i++) {
      if (saving.genability_data.postSolar[i]) {
        postSolar[i] = saving.genability_data.postSolar[i].cost;
      } else {
        postSolar[i] = 0;
      }
      if (saving.genability_data.preSolar[i]) {
        preSolar[i] = saving.genability_data.preSolar[i].cost;
      } else {
        preSolar[i] = 0;
      }
    }
    Highcharts.chart( {
      chart: {
        renderTo : 'line-container' + saving.bid_rank,
        // alignThresholds: true,
        animation: false
      },
      title: {
        text: "",
        align: "left",
      },

      subtitle: {
        text: "",
        align: "center",
      },

      yAxis: {
        // type: 'logarithmic',
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            //@ts-ignore
            if (Math.floor(this.value).toString().length <= 3) {
              if (this.value >= 0) {
                return (
                  "$" +
                  this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              } else {
                //@ts-ignore
                return ("-$" +Math.abs(this.value)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              }
            } else  {
                //@ts-ignore
              if (Math.floor(this.value).toString().length <= 6){
                if (this.value >= 0) {
                    //@ts-ignore
                  return "$" + Math.round(this.value / 1000) + "K";
                } else {
                    //@ts-ignore
                  return "-$" + Math.round((this.value * -1) / 1000) + "K";
                }
              }  //@ts-ignore
              if (Math.floor(this.value).toString().length > 6){
                if (this.value >= 0) {
                    //@ts-ignore
                  return "$" + Math.round(this.value / 1000000) + "M";
                } else {
                    //@ts-ignore
                  return "-$" + Math.round((this.value * -1) / 1000000) + "M";
                }
              }

            }
          },
        },
      },

      xAxis: {
        // type: 'logarithmic',
        accessibility: {
          rangeDescription: "Range: 0 to 25",
        },
        title: {
          text: "Years",
        },
        tickInterval: 5,
      },

      legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "top",
        // shadow:true,
        symbolWidth: 8,
        symbolHeight: 8,
        useHTML: true,
        itemWidth: 200  ,
        itemMarginTop: 10,
        itemMarginBottom: 10,
        // backgroundColor: 'rgba(241, 241, 241, 0.6)',
        itemStyle: {
          color: "#666666",
          fontWeight: "normal",
          fontSize: "12px",
        },
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          marker: {
            symbol: 'circle'
          }
          // pointStart: 0
        },
      },

      series: [
        {
          name: "Utility Bill Without Solar",
          color: "#B0B0B0",
          data: preSolar,
          type:'line',
        },
        {
          name: "Utility Bill With Solar",
          color: "#EF992F",
          data: postSolar,
          type:'line'

        },
        // {
        //   name: "Annual Saving",
        //   color: "#CC4411",
        //   data: annual_saving,
        //   type:'line'

        // },
        // {
        //   name: "Cumulative Saving",
        //   color: "#48BC25",
        //   data: CumulativeSaving,
        //   type:'line'

        // },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "top",
              },
            },
          },
        ],
      },
      credits: {
        enabled: false,
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.data) {
      this.data.bids.map(bid => {
        this.graphInit(bid);
        // this.displayGraph(bid);
        this.Nem3(bid);

      });
    }
  }

}
