import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-immediate-saving',
  templateUrl: './immediate-saving.component.html',
  styleUrls: ['./immediate-saving.component.scss']
})
export class ImmediateSavingComponent implements OnInit {
  @Input() bid: any;
  data: any;
  cashSaved: number;
  cashSavedHeight: number;
  cashUtility: number;
  cashUtilityHeight: number;
  cashLeftHeight: number;

  loanSaved: number;
  loanSavedHeight: number;
  loanSEP: number;
  loanSEPHeight: number;
  loanEB: number;
  loanEBHeight: number;
  loanEBLeft: number;
  finType:any;
  toggleData: any;
  SEVloanSavedHeight: number;
  SEVloanSEPHeight: number;
  SEVloanEBHeight: number;
  SEVavgloan: number;
  selectedBattery: any;



  constructor() { }
  ngOnInit(): void {
      this.data = JSON.parse(sessionStorage.getItem('pdfData'));
      this.toggleData=JSON.parse(sessionStorage.getItem('toggleData'));
      this.selectedBattery = sessionStorage.getItem('batteryIndex')
      this.finType= this.data.finType;
      const root = this;
      if (this.bid.avg_monthly_savings === '-') {
      // Cash
      root.cashSaved = 0;
      root.cashUtility = this.bid.new_bill;
      const totalUserBill = root.cashSaved + root.cashUtility;

      if (root.data.avgMonthlyBill >= totalUserBill) {
        console.log('1');
        root.cashLeftHeight = 100;

        root.cashSavedHeight =
          (root.cashSaved / root.data.avgMonthlyBill) * 100;

        root.cashUtilityHeight =
          (root.cashUtility / root.data.avgMonthlyBill) * 100;
        console.log(
          root.cashSaved,
          root.cashSavedHeight,
          root.cashUtility,
          root.cashUtilityHeight
        );
      } else {
        console.log('2');
        root.cashLeftHeight =
          (root.data.avgMonthlyBill / totalUserBill) * 100;
        root.cashSavedHeight = (root.cashSaved / totalUserBill) * 100;

        root.cashUtilityHeight = (root.cashUtility / totalUserBill) * 100;
        console.log(
          root.cashSaved,
          root.cashSavedHeight,
          root.cashUtility,
          root.cashUtilityHeight
        );
      }

      // Loan

      root.loanSaved = 0;
      root.loanSEP = Number(this.bid.avg_loan);
      root.loanEB = Number(this.bid.new_bill) - Number(this.bid.avg_loan);

      const rightTotalLoan = root.loanSaved + root.loanSEP + root.loanEB;
      if (root.data.avgMonthlyBill > rightTotalLoan) {
        root.loanSavedHeight = (root.loanSaved / rightTotalLoan) * 100;

        root.loanSEPHeight = (root.loanSEP / rightTotalLoan) * 100;

        root.loanEBHeight = (root.loanEB / rightTotalLoan) * 100;

        root.loanEBLeft = 100;
        console.log(
          '------loan/1',
          root.loanSaved,
          root.loanSavedHeight,
          root.loanSEP,
          root.loanSEPHeight,
          root.loanEB,
          root.loanEBHeight,
          root.loanEBLeft
        );
      } else {
        root.loanSavedHeight = (root.loanSaved / rightTotalLoan) * 100;

        root.loanSEPHeight = (root.loanSEP / rightTotalLoan) * 100;

        root.loanEBHeight = (root.loanEB / rightTotalLoan) * 100;

        root.loanEBLeft = (root.data.avgMonthlyBill / rightTotalLoan) * 100;
        console.log(
          '------loan/2',
          root.loanSaved,
          root.loanSavedHeight,
          root.loanSEP,
          root.loanSEPHeight,
          root.loanEB,
          root.loanEBHeight,
          root.loanEBLeft
        );
      }
    } else {
      // Cash
      root.cashSaved = this.bid.avg_monthly_savings;
      root.cashUtility = this.bid.new_bill;
      const totalUserBill = root.cashSaved + root.cashUtility;

      if (root.data.avgMonthlyBill >= totalUserBill) {
        console.log('1');
        root.cashLeftHeight = 100;

        root.cashSavedHeight =
          (root.cashSaved / root.data.avgMonthlyBill) * 100;

        root.cashUtilityHeight =
          (root.cashUtility / root.data.avgMonthlyBill) * 100;
        console.log(
          root.cashSaved,
          root.cashSavedHeight,
          root.cashUtility,
          root.cashUtilityHeight
        );
      } else {
        console.log('2');
        root.cashLeftHeight =
          (root.data.avgMonthlyBill / totalUserBill) * 100;
        root.cashSavedHeight = (root.cashSaved / totalUserBill) * 100;

        root.cashUtilityHeight = (root.cashUtility / totalUserBill) * 100;
        console.log(
          root.cashSaved,
          root.cashSavedHeight,
          root.cashUtility,
          root.cashUtilityHeight
        );
      }

      // Loan

      root.loanSaved = Number(this.bid.avg_monthly_savings);
      root.loanSEP = Number(this.bid.avg_loan);
      root.loanEB = Number(this.bid.new_bill) - Number(this.bid.avg_loan);

      const rightTotalLoan = root.loanSaved + root.loanSEP + root.loanEB;
      if (root.data.avgMonthlyBill > rightTotalLoan) {
        root.loanSavedHeight = (root.loanSaved / rightTotalLoan) * 100;

        root.loanSEPHeight = (root.loanSEP / rightTotalLoan) * 100;

        root.loanEBHeight = (root.loanEB / rightTotalLoan) * 100;

        root.loanEBLeft = 100;
        console.log(
          '------loan/1',
          root.loanSaved,
          root.loanSavedHeight,
          root.loanSEP,
          root.loanSEPHeight,
          root.loanEB,
          root.loanEBHeight,
          root.loanEBLeft
        );
      } else {
        root.loanSavedHeight = (root.loanSaved / rightTotalLoan) * 100;

        root.loanSEPHeight = (root.loanSEP / rightTotalLoan) * 100;

        root.loanEBHeight = (root.loanEB / rightTotalLoan) * 100;

        root.loanEBLeft = (root.data.avgMonthlyBill / rightTotalLoan) * 100;
        console.log(
          '------loan/2',
          root.loanSaved,
          root.loanSavedHeight,
          root.loanSEP,
          root.loanSEPHeight,
          root.loanEB,
          root.loanEBHeight,
          root.loanEBLeft
        );
      }
    }

if (
      this.data.batteryData && this.data.batteryData[this.selectedBattery].avg_loan) {
      let EVtotalLoan =
        Number(
          this.data.batteryData[
            this.selectedBattery
          ].avg_loan
        ) +
        this.loanSaved +
        this.loanSEP +
        this.loanEB;
      this.SEVloanSavedHeight = (this.loanSaved / EVtotalLoan) * 100;

      this.SEVloanSEPHeight = (this.loanSEP / EVtotalLoan) * 100;

      this.SEVloanEBHeight = (this.loanEB / EVtotalLoan) * 100;

      this.SEVavgloan =
        (Number(
          this.data.batteryData[
            this.selectedBattery
          ].avg_loan
        ) /
          EVtotalLoan) *
        100;

      this.loanSavedHeight = (this.loanSaved / EVtotalLoan) * 100;

      this.loanSEPHeight = (this.loanSEP / EVtotalLoan) * 100;

      this.loanEBHeight = (this.loanEB / EVtotalLoan) * 100;
      this.cashLeftHeight = (root.data.avgMonthlyBill / EVtotalLoan) * 100;  // this.data.avgMonthlyBill
    }
   
  }

}
