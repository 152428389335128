import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CDN_URL } from '../app.constants';
import { AppService } from '../app.service';

@Component({
  selector: 'app-hubble-battery-pdf',
  templateUrl:'./hubble-battery-pdf.component.html',
  styleUrls: ['./hubble-battery-pdf.component.scss'],

})
export class HubbleBatteryPdfComponent implements OnInit {

  cdnURL = CDN_URL;
  data: any;
  public componentLocation = 'text';
  constructor( public appService: AppService) { }

  ngOnInit(): void {
    this.data = JSON.parse(sessionStorage.getItem('hubblePDF'));
    console.log(this.data);
    if (this.data.hasOwnProperty('color')){
     this.appService.setProps(this.data.color);
    } else {
      this.appService.setColors(this.data.partner_type);
    }
    this.appService.setLanguage(this.data.partner_type);
  }

  objectKeys(obj) {
    if (typeof obj === 'undefined') { return undefined; }
    if (obj === 'null') { return null; }
    return Object.keys(obj);
  }
 }
