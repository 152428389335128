<!-- <div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 no-padding imm-savings-graph loan">
    <div class="col-sm-6 col-md-6 col-lg-6  no-padding im-sav-left graph-min-height">
      <div class="tooltipss">
        <div class="col-sm-12 col-md-12 col-lg-12 no-padding" [style.height.%]='100-loanEBLeft'
             *ngIf="loanEBLeft!=100">
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 no-padding eb-savings" [style.height.%]='loanEBLeft'>
          <p class="one ng-binding"> {{data.avgMonthlyBill | currency : 'USD' : 'symbol' : '1.0' }}/mo<br>Energy
            Bill</p>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-6 no-padding im-sav-right graph-min-height">
      <div *ngIf="loanEBHeight>15; then uilityLShow else utilityLHide">here is ignored</div>

      <ng-template #uilityLShow>
        <div class="tooltipss" [style.height.%]="loanSavedHeight+loanSEPHeight+loanEBHeight">
          <div class="col-sm-12 col-md-12 col-lg-12 no-padding saved-right" [style.height.%]="loanSavedHeight"
               *ngIf="loanSavedHeight>18" style="display: flex">
            <p class="one" style="margin: auto"> {{loanSaved | currency : 'USD' : 'symbol' : '1.0' }}/mo<br>Saved</p>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 no-padding saved-right" [style.height.%]="loanSavedHeight"
               *ngIf="loanSavedHeight<=18 && loanSavedHeight>0 " style="display: flex">
            <div class="tooltiptextss" style="margin: auto">
              <p class="one"> {{loanSaved | currency : 'USD' : 'symbol' : '1.0' }}/mo<br>Saved</p>
              <i></i>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 no-padding saved-right" style="border:0;position: absolute;display: flex"
               [style.height.%]="loanSavedHeight" *ngIf="loanSavedHeight==0">
            <div class="tooltiptextss" style="margin: auto">
              <p class="one"> {{loanSaved | currency : 'USD' : 'symbol' : '1.0' }}/mo<br>Saved</p>
              <i></i>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12 no-padding utility-right sep-right"
               [style.height.%]="loanSEPHeight" *ngIf="loanSEPHeight>18" style="display: flex">
            <p class="two" style="margin: auto"> {{ loanSEP | currency : 'USD' : 'symbol' : '1.0' }}/mo<br>Solar Energy Payment</p>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 no-padding utility-right sep-right"
               [style.height.%]="loanSEPHeight" *ngIf="loanSEPHeight<=18" style="display: flex">
            <div class="tooltiptextss" style="margin: auto">
              <p class="two"> {{ loanSEP | currency : 'USD' : 'symbol' : '1.0' }}/mo<br>Solar Energy Payment</p>
              <i></i>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12 no-padding utility-right enrg-bill-right"
               [style.height.%]="loanEBHeight" style="display: flex">
            <p class="two" style="margin: auto"> {{loanEB | currency : 'USD' : 'symbol' : '1.0' }}/mo<br>Utility Payment</p>
          </div>
        </div>
      </ng-template>
      <ng-template #utilityLHide>
        <div class="tooltipss" [style.height.%]="loanSavedHeight+loanSEPHeight+loanEBHeight">
          <div class="col-sm-12 col-md-12 col-lg-12 no-padding saved-right" [style.height.%]="loanSavedHeight"
               *ngIf="loanSavedHeight>18" style="display: flex">
            <p class="one" style="margin: auto"> {{loanSaved | currency : 'USD' : 'symbol' : '1.0' }}/mo<br>Saved</p>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 no-padding saved-right" [style.height.%]="loanSavedHeight"
               *ngIf="loanSavedHeight<=18 && loanSavedHeight>0 " style="display: flex">
            <div class="tooltiptextss" style="margin: auto">
              <p class="one"> {{loanSaved | currency : 'USD' : 'symbol' : '1.0' }}/mo<br>Saved</p>
              <i></i>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 no-padding saved-right" style="border:0;display: flex"
               [style.height.%]="loanSavedHeight" *ngIf="loanSavedHeight==0">
            <div class="tooltiptextss" style="margin: auto">
              <p class="one"> {{loanSaved | currency : 'USD' : 'symbol' : '1.0' }}/mo<br>Saved</p>
              <i></i>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 no-padding utility-right sep-right"
               [style.height.%]="loanSEPHeight" *ngIf="loanSEPHeight>18" style="display: flex">
            <p class="two" style="margin: auto"> {{ loanSEP | currency : 'USD' : 'symbol' : '1.0' }}/mo<br>Solar Energy Payment</p>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 no-padding utility-right sep-right"
               [style.height.%]="loanSEPHeight" *ngIf="loanSEPHeight<=18" style="display: flex">
            <div class="tooltiptextss" style="margin: auto">
              <p class="two"> {{ loanSEP | currency : 'USD' : 'symbol' : '1.0' }}/mo<br>Solar Energy Payment</p>
              <i></i>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12 no-padding utility-right enrg-bill-right"
               [style.height.%]="loanEBHeight" style="display: flex">
            <div class="tooltiptextss" style="margin: auto">
              <p class="two"> {{loanEB | currency : 'USD' : 'symbol' : '1.0' }}/mo<br>Utility Payment</p>
              <i></i>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-6 no-padding before-solar before-after-solar">
      <h5>Before Solar</h5>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-6 no-padding after-solar before-after-solar">
      <h5>After Solar</h5>
    </div>
  </div>
</div> -->
<div  class="imm-s">
  <div class="BeforeSolarcontainer">
    <span class="headImmediate">Before Solar</span>
    <div class="AfterSolar UtilityPayment" [style.width.%]="cashLeftHeight"><span *ngIf="cashLeftHeight > 10" class="amount
      ">{{data.avgMonthlyBill | currency : 'USD' : 'symbol' : '1.0-0' }}/Mo </span> <span *ngIf="cashLeftHeight < 10"
        class="Pricetooltiptext">{{data.avgMonthlyBill | currency : 'USD' : 'symbol' : '1.0-0' }}/Mo </span> </div>
  </div>
  <div class="BeforeSolarcontainer">
    <span class="headImmediate">After Solar</span>
    <div class="container">
      <div class="row amt-rel" *ngIf="finType=='cash'">
        <div class="AfterSolar UtilityPayment  col-xs-6" [style.width.%]="cashUtilityHeight"><span
            *ngIf="cashUtilityHeight > 10" class="amount">{{cashUtility | currency : 'USD' : 'symbol' : '1.0-0'
            }}/Mo</span>
          <span *ngIf="cashUtilityHeight < 10" class="Pricetooltiptext ">{{cashUtility | currency : 'USD' : 'symbol' :
            '1.0-0' }}/Mo </span>
        </div>
        <div class="AfterSolar col-xs-6" [style.width.%]="cashSavedHeight"><span *ngIf="cashSavedHeight > 10"
            class="amount">{{cashSaved | currency : 'USD' : 'symbol' : '1.0' }}/Mo</span>
          <span *ngIf="cashSavedHeight < 10" class="Pricetooltiptext ">{{cashSaved | currency : 'USD' : 'symbol' :
            '1.0-0' }}/Mo </span>
        </div>
        <p class="amnt">
          {{(cashUtility + cashSaved) | currency : 'USD' : 'symbol' : '1.0-0'}}/Mo
        </p>
      </div>
      <div class="row amt-rel" *ngIf="finType=='loan'">
        <div class="AfterSolar UtilityPayment  col-xs-6" [style.width.%]="loanEBHeight"><span
            *ngIf="loanEBHeight > 10" class="amount">{{loanEB | currency : 'USD' : 'symbol' : '1.0-0' }}/Mo</span>
          <span *ngIf="loanEBHeight < 10" class="Pricetooltiptext ">{{loanEB | currency : 'USD' : 'symbol' : '1.0-0'
            }}/Mo </span>
        </div>
        <div class="AfterSolar SolarPayment col-xs-6" [style.width.%]="loanSEPHeight"><span *ngIf="loanSEPHeight > 10"
            class="amount">{{ loanSEP | currency : 'USD' : 'symbol' : '1.0' }}/Mo</span>
          <span *ngIf="loanSEPHeight < 10" class="Pricetooltiptext ">{{loanSEP | currency : 'USD' : 'symbol' : '1.0-0'
            }}/Mo </span>
        </div>
        <div class="AfterSolar col-xs-6" [style.width.%]="loanSavedHeight"><span *ngIf="loanSavedHeight > 10"
            class="amount">{{loanSaved | currency : 'USD' : 'symbol' : '1.0' }}</span>
          <span *ngIf="loanSavedHeight < 10" class="Pricetooltiptext ">{{loanSaved | currency : 'USD' : 'symbol' :
            '1.0-0' }}/Mo </span>
        </div>
        <p class="amnt">
          {{(loanEB + loanSEP + loanSaved) | currency : 'USD' : 'symbol' : '1.0-0'}}/Mo
        </p>
      </div>

    </div>
  </div>
  <!--- Solar+Battery-->

  <div *ngIf="toggleData.toggleSettings.homeBatterySolutions" class="BeforeSolarcontainer">
    <span class="headImmediate">Solar + Battery</span>
    <div class="container">
      <div class="row amt-rel" *ngIf="finType=='cash'">
        <div class="AfterSolar UtilityPayment  col-xs-6" [style.width.%]="cashUtilityHeight"><span
            *ngIf="cashUtilityHeight > 10" class="amount">{{cashUtility | currency : 'USD' : 'symbol' : '1.0-0'
            }}/Mo</span>
          <span *ngIf="cashUtilityHeight < 10" class="Pricetooltiptext">{{cashUtility | currency : 'USD' : 'symbol' :
            '1.0-0' }}/Mo</span>
        </div>
        <div class="AfterSolar col-xs-6" [style.width.%]="cashSavedHeight"><span *ngIf="cashSavedHeight > 10"
            class="amount">{{cashSaved | currency : 'USD' : 'symbol' : '1.0' }}/Mo</span>
          <span *ngIf="cashSavedHeight < 10" class="Pricetooltiptext">{{cashSaved | currency : 'USD' : 'symbol' :
            '1.0-0' }}/Mo</span>
        </div>
        <p class="amnt">
          {{(cashUtility + cashSaved) | currency : 'USD' : 'symbol' : '1.0-0'}}/Mo
        </p>
      </div>
      <div class="row amt-rel" *ngIf="finType=='loan'">
        <div class="AfterSolar UtilityPayment  col-xs-6" [style.width.%]="SEVloanEBHeight"><span
            *ngIf="SEVloanEBHeight > 10" class="amount">{{loanEB | currency : 'USD' : 'symbol' : '1.0-0' }}/Mo</span>
          <span *ngIf="SEVloanEBHeight < 10" class="Pricetooltiptext">{{loanEB | currency : 'USD' : 'symbol' : '1.0-0'
            }}/Mo</span>
        </div>
        <div class="AfterSolar SolarPayment col-xs-6" [style.width.%]="SEVloanSEPHeight"><span
            *ngIf="SEVloanSEPHeight > 10" class="amount">{{ loanSEP | currency : 'USD' : 'symbol' : '1.0-0' }}/Mo</span>
          <span *ngIf="SEVloanSEPHeight < 10" class="Pricetooltiptext">{{loanSEP | currency : 'USD' : 'symbol' : '1.0-0'
            }}/Mo</span>
        </div>
        <div class="AfterSolar BatteryPayment col-xs-6" [style.width.%]="SEVavgloan"><span *ngIf="SEVavgloan > 10"
            class="amount">{{ data.batteryData[selectedBatteryy].avg_loan |
            currency : 'USD' : 'symbol' : '1.0-0' }}/Mo</span>
          <span *ngIf="SEVavgloan < 10"
            class="Pricetooltiptext">{{data.batteryData[selectedBattery].avg_loan
            | currency : 'USD' : 'symbol' : '1.0-0' }}/Mo</span>
        </div>
        <div class="AfterSolar MonthlySavings col-xs-6" [style.width.%]="SEVloanSavedHeight"><span
            *ngIf="SEVloanSavedHeight > 10" class="amount">{{loanEB | currency : 'USD' : 'symbol' : '1.0-0' }}/Mo</span>
          <span *ngIf="SEVloanSavedHeight < 10" class="Pricetooltiptext">{{loanEB | currency : 'USD' : 'symbol' :
            '1.0-0' }}/Mo</span>
        </div>
        <p class="amnt">
          {{(loanEB + loanSEP + loanSaved + data.batteryData[selectedBattery].avg_loan) | currency : 'USD' : 'symbol' : '1.0-0'}}/Mo
        </p>
      </div>
    </div>
  </div>

  <!--- Solar_Battery-->

  <div class="BeforeSolarFootercontainer">
    <div class="Footercontainer">
      <table>
        <tr>
          <td>
            <span class="dot UtilityPayment"></span>&nbsp;<span class="dotName">Utility Payment</span>
          </td>
          <td *ngIf="finType=='loan'">
            <span class="dot SolarPayment"></span>&nbsp;<span class="dotName">Solar Energy Payment</span>
          </td>
          <td *ngIf="finType=='loan'">
            <span class="dot BatteryPayment"></span>&nbsp;<span class="dotName">Home Battery Payment</span>
          </td>
          <td>
            <span class="dot MonthlySavings"></span>&nbsp;<span class="dotName">Monthly Savings</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
