import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImmediateSavingComponent } from './immediate-saving/immediate-saving.component';


@NgModule({
  declarations: [ImmediateSavingComponent],
  imports: [
    CommonModule
  ],
  exports : [ImmediateSavingComponent]
})
export class SharedModule { }
