import { Injectable } from '@angular/core';
import {COLORS} from './configuration/colors-config';
import {LANG} from './configuration/language-config';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor() {
  }
  public setColors = (type) => {
    if (COLORS.root.hasOwnProperty(type)){
      this.setProps(COLORS.root[type]);

    } else {
      this.setProps(COLORS.root.basic);
    }
  };
  public setProps = (color) => {
    document.documentElement.style.setProperty('--color-1', color.color1);
    document.documentElement.style.setProperty('--color-2', color.color2);
    document.documentElement.style.setProperty('--color-3', color.color3);
    document.documentElement.style.setProperty('--color-4', color.color4);
    document.documentElement.style.setProperty('--color-5', color.color5);
    document.documentElement.style.setProperty('--color-6', color.color6);
    document.documentElement.style.setProperty('--color-7', color.color7);
    document.documentElement.style.setProperty('--color-8', color.color8);
  }
  public setLanguage = (type) => {
    if (LANG.main.hasOwnProperty(type)){
      LANG.data.text = Object.assign(LANG.main.default, LANG.main[type]);
      console.log(LANG.data);
    } else {
      LANG.data.text = Object.assign(LANG.main.default);
    }
  }
}
