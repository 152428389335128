<div class="pdf-output" *ngIf="data">
  <!--First Page-->
  <section class="pdf-intro">
    <div class="pdf-title">
      <div class="header-box">
        <i class="pms-icon title-icon icon-energy-icon" title="Icon - battery" label="icon"></i>
        <h1 class="offers">Your Offers</h1>
        <address>
          <span>{{data.user.userName}}</span><br>
          <div *ngIf="data.user.streetAddress" class="street-address">
            {{data.user.streetAddress}},<br>
            {{data.user.city?data.user.city+', ':''}}{{data.user.state?data.user.state+' ':''}}{{data.user.zip?data.user.zip:''}}
          </div>
          <div *ngIf="!data.user.streetAddress">
            {{data.user.address}}
          </div><br>
        </address>
        <img [src]="'brand_logo'| lang: componentLocation" alt="Brand logo image" class="logo-image" />
      </div>
    </div>
    <div class="header-image" [style.backgroundImage]="'url('+ data.images.satelliteImage +')'" aria-label="Satellite image" title="Satellite image"></div>
  </section>

  <!--Start Design Studio Section-->
  <section>
    <div class="offers-section-inner">
      <h1 class="heading">Your Designs</h1>
      <hr class="dark-hr" />
      <div class="design-images-tab" >
        <div class="div-half">
          <img [src]="data.images.satelliteImage" class='iconDetails' alt="Satellite image">
        </div>
        <div class="design-tab-title">
          <h2 >Satellite</h2>
        </div>
      </div>
      <div class="design-images-tab" >
        <div class="div-half">
          <img [src]="data.images.threeDImage" class='iconDetails' alt="3D Image">
        </div>
        <div class="design-tab-title">
          <h2 >3D</h2>
        </div>
      </div>
      <div class="design-images-tab" >
        <div class="div-half">
          <img [src]="data.images.shadingImage" class='iconDetails' alt="Shading Image">
        </div>
        <div class="design-tab-title">
          <h2 >Shading</h2>
        </div>
      </div>
    </div>
  </section>
  <!--End Design Studio Section-->




  <!--Start Solar Offers Section-->
  <section>
    <div class="solar-offers">
      <h1 class="heading">Your Solar Offers</h1>
      <hr class="dark-hr">
      <div class="d-flex align-items-center">
        <i class="pms-icon mr-12 icon-Financing-Presets"></i>
        <h2>Financing Presets</h2>
      </div>
      <br>
      <div class="row">
        <div class="col-sm-6 financing-presets">
          <p>Financing Option:&nbsp;<span>{{data.finType | titlecase}}</span></p>
          <p *ngIf="data.finType != 'cash'">Down Payment:&nbsp;<span>{{data.downPayment | currency : 'USD' : 'symbol' : '1.0' }}</span></p>
        </div>
        <div class="col-sm-6 financing-options-change">
          <div class="material-icons-div" title="Error outline icon" aria-label="Error outline icon">
            <i class="pms-icon icon-i-icon"></i>
          </div>
          <div class="fd">
            <p><span>Need to change financing options?</span> Change it online on the {{'bid'| lang: componentLocation}}s page and re-download the
              Proposal.</p>
          </div>
        </div>
      </div>
      <br>
      <div class="quick-overview-title align-items-center">
        <i class="pms-icon mr-12 icon-Quick-Overview"></i>
        <h2>Quick Overview</h2>
      </div>
      <div class="row bid-list-blur">
        <div
          [ngClass]="{'col-sm-4': data.bids.length > 2, 'col-sm-6': data.bids.length == 2, 'col-sm-12': data.bids.length < 2,'border-left' : !isFirstRow }"
          class="bid-list" *ngFor="let bid of data.bids; let isFirstRow = first">
          <h2>{{'Bid'| lang: componentLocation}} {{bid.bid_rank}}</h2>
          <hr>
          <div class="bid-list-item">
            <h5 class="first-bid-list-item battery-2">{{bid.new_bill | currency : 'USD' : 'symbol' : '1.0' }}<span> (vs.
              {{data.avgMonthlyBill | currency : 'USD' : 'symbol' : '1.0' }})</span></h5>
            <h6>New Energy Bill</h6>
          </div>
          <div *ngIf="data.finType=='cash'" class="bid-list-item">
            <h5>{{bid.payback_period}} years</h5>
            <h6>Payback Period</h6>
          </div>
          <div *ngIf="data.finType!=='cash'" class="bid-list-item">
            <h5>{{bid.down_payment | currency : 'USD' : 'symbol' : '1.0' }}</h5>
            <h6>Upfront Payment</h6>
          </div>
          <div *ngIf="data.finType!=='cash'" class="bid-list-item">
            <h5>{{bid.term}} Years</h5>
            <h6>Loan Term</h6>
          </div>
          <div *ngIf="bid.lifetime_savings" class="bid-list-item">
            <h5>{{bid.lifetime_savings | currency : 'USD' : 'symbol' : '1.0' }}</h5>
            <h6>Lifetime Savings</h6>
          </div>
          <div *ngIf="bid.equipment.system_size" class="bid-list-item">
            <h5>{{bid.equipment.system_size}}kW</h5>
            <h6>System Size</h6>
          </div>
          <div style="display: flex;">
            <img [src]="bid.installer.logo_url" alt="Installer logo image">
          </div>
        </div>
      </div>
      <br />
    </div>
  </section>
    <div *ngFor="let bi of data.bids">

      <!--production & Consumption-->
      <section class="pdf-intro">
        <div class="system-details">
          <div class="system-details-title">
            <div class="system-details-header">
              <h1 class="heading">Your Solar Offers</h1>
            </div>
            <div class="system-details-title-info">
              <h1 class="bid-rank">{{'Bid' | lang: componentLocation}} {{bi.bid_rank}}</h1>
              <div class="bid-type-title">
                <i class="pms-icon icon-System-Details"></i>
                <h3>System Details</h3>
              </div>
            </div>
          </div>
          <hr class="dark-hr" />
          <div class="system-details-chart">
            <h3 >Production & Consumption</h3>
            <div class="legends" aria-label="System-detail graph between production & consumption" title="System-detail graph between production & consumption">
              <div style="display: flex">
                <div class="production-color"></div>Production
              </div>
              <div style="display: flex">
                <div class="consumption-color"></div>Consumption
              </div>
            </div>
          </div>
          <br />
          <div id="system-details-chart{{bi.bid_rank}}" class="system-details-chart"></div>
          <div class="row">
            <div class="col-sm-4">
              <h2 >Overview</h2>
              <hr />
              <div *ngIf="data.finType!=='cash'">
                <h3>{{bi.avg_loan | currency : 'USD' : 'symbol' : '1.0' }}</h3>
                <h4>Monthly Loan Payment</h4>
              </div>
              <div *ngIf="data.finType!=='cash'">
                <h3>{{bi.new_bill-bi.avg_loan | currency : 'USD' : 'symbol' : '1.0' }}</h3>
                <h4>Utility Payment</h4>
              </div>
              <div>
                <h3>{{bi.equipment.system_size}} kW</h3>
                <h4>System Size</h4>
              </div>
              <div>
                <h3>{{bi.equipment.annual_consumption | number:'1.0'}} kWh</h3>
                <h4>Annual Consumption</h4>
              </div>
              <div>
                <h3>{{bi.equipment.annual_production | number:'1.0'}} kWh</h3>
                <h4>Annual Production</h4>
              </div>
              <!-- <div>
                <h3>{{bi.bill_reduction}}%</h3>
                <h4>Bill Reduction</h4>
              </div> -->
            </div>
            <div class="col-sm-4">
              <h2 >Panels</h2>
              <hr />
              <div>
                <h3 class="color-2">{{bi.equipment.panel.model}}</h3>
                <h4>Panel Model</h4>
              </div>
              <div>
                <h3>{{bi.equipment.panel.efficiency}}%</h3>
                <h4>Efficiency</h4>
              </div>
              <div>
                <h3>{{bi.equipment.panel.color}}</h3>
                <h4>Color</h4>
              </div>
              <div>
                <h3>{{bi.equipment.panel.origin}}</h3>
                <h4>Origin</h4>
              </div>
              <div>
                <h3>{{bi.equipment.panel.warranty}} years</h3>
                <h4>Performance Warranty</h4>
              </div>
            </div>
            <div class="col-sm-4">
              <h2 >Inverter</h2>
              <hr />
              <div>
                <h3 class="color-2">{{bi.equipment.inverter.maker}}</h3>
                <h4>Inverter Model</h4>
              </div>
              <div>
                <h3>{{bi.equipment.inverter.warranty}} years</h3>
                <h4>Warranty</h4>
              </div>
              <div>
                <h3>{{bi.equipment.inverter.maker}}</h3>
                <h4>Monitoring System</h4>
              </div>
              <h2 *ngIf="bi.equipment.optimizer && bi.equipment.optimizer !='N/A'" >Optimizer</h2>
              <hr />
              <div  *ngIf="bi.equipment.optimizer && bi.equipment.optimizer !='N/A'">
                <h3 class="color-2">{{bi.equipment.optimizer}}</h3>
                <h4>Optimizer Model</h4>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section class="pdf-intro">
        <div class="financials-breakdown">
          <div class="system-details-title">
            <div class="system-details-header">
              <h1 class="heading">Your Solar Offers</h1>
            </div>
            <div class="system-details-title-info">
              <h1 class="bid-rank">{{'Bid' | lang: componentLocation}} {{bi.bid_rank}}</h1>
              <div class="bid-type-title">
                <i class="pms-icon icon-Financials"></i>
                <h3>Financials</h3>
              </div>
            </div>
          </div>
          <hr class="dark-hr"  style="margin:0"/>
          <div class="financials-chart-x">
            <h3>Projected Utility Costs</h3>
          </div>
          <!-- <div class="financials-chart" id="financials-container{{bi.bid_rank}}">
          </div> -->
          <!-- <div class="col-lg-12 col-md-12 col-sm-12 no-padding single-bid-left imm-savings" aria-label="Immediate savings graph" title="Immediate savings graph">
            <h5 class="two-heads-graphs">Immediate Savings</h5>
            <app-immediate-saving [bid]="bi"></app-immediate-saving>
          </div> -->
          <div class="col-lg-12 col-md-12 col-sm-12 no-padding long-term-savings-graph" aria-label="Long-Term savings graph" title="Long-Term savings graph">
            <!-- <h5 class="two-heads-graphs">Projected Savings</h5> -->
            <!-- <div class="financials-chart" id="financials-container{{bi.bid_rank}}"> -->
            <div  id="line-container{{bi.bid_rank}}" style="min-width: 400px; height: 455px; margin: 0 auto">
            </div>
          </div>
          <div class="row financial-row">
            <div class="col-sm-4">
              <div class="breakdown-list">
                <h2>Breakdown</h2>
                <hr />
                <div class="first-item">
                  <h3>Gross Price</h3>
                  <h4 class="financials-price">{{bi.gross_price | currency : 'USD' : 'symbol' : '1.0' }}</h4>
                </div>
                <div>
                  <h4>{{data.finType | titlecase}} Discount</h4>
                  <h4 class="financials-price">{{-bi.cash_discount | currency : 'USD' : 'symbol' : '1.0' }}</h4>
                </div>
                <div *ngIf="bi.discounts?.discount?.bidDiscounts" class="flex-column p-0">
                  <div *ngFor="let discount of bi.discounts.discount.bidDiscounts">
                    <h4>{{discount.discountName}}</h4>
                    <h4 class="financials-price">
                      -{{discount.discount | currency : 'USD' : 'symbol' : '1.0' }}
                    </h4>
                  </div>
                </div>
                <div *ngIf="bi.incentives.state_rebate_total_value">
                  <h4>{{bi.incentives.state_rebates.name}}</h4>
                  <h4 class="financials-price">
                    {{-bi.incentives.state_rebates.value | currency : 'USD' : 'symbol' : '1.0' }}</h4>
                </div>
                <div class="first-item contract-price">
                  <h3>Contract Price</h3>
                  <h4 class="financials-price">{{bi.contract_value | currency : 'USD' : 'symbol' :'1.0'}}</h4>
               </div>

                <div *ngIf="bi.incentives.federal_tax_credit_total_value">
                  <h4>Federal Tax Credit</h4>
                  <h4 class="financials-price">
                    {{-bi.incentives.federal_tax_credit_total_value | currency : 'USD' : 'symbol' : '1.0-0' }}</h4>
                </div>
                <div *ngFor="let tax of bi.incentives.state_tax_credits">
                  <h4>{{tax.name}}</h4>
                  <h4 class="financials-price">
                    {{-tax.value | currency : 'USD' : 'symbol' : '1.0-0' }}</h4>
                </div>
                <div *ngFor="let tax of bi.incentives.utility_rebates">
                  <h4>{{tax.name}}</h4>
                  <h4 class="financials-price">
                    {{-tax.value | currency : 'USD' : 'symbol' : '1.0' }}</h4>
                </div>
                <div class="first-item">
                  <h3>Net Price</h3>
                  <h4>{{bi.net_price | currency : 'USD' : 'symbol' : '1.0' }}</h4>
                </div>
              </div>
              <hr />
              <div class="bill-reduction" *ngIf="data.finType=='cash'">
                <h4>{{bi.ppw | currency : 'USD' : 'symbol' : '1.0-2' }}</h4>
                <h4 class="financial-description">Price Per Watt (PPW)</h4>
              </div>
              <div class="bill-reduction" *ngIf="data.finType=='cash'">
                <h4>{{bi.cost_per_kwh | number : '1.1' }} ¢
                  <span class="financial-description">(vs. {{bi.cost_per_kwh_without_solar | number : '1.1-1'}} ¢)</span>
                </h4>
                <h4 class="financial-description">Cost Per kWh</h4>
              </div>
              <div class="bill-reduction">
                <h4>{{bi.bill_reduction}}%</h4>
                <h4 class="financial-description">Bill Reduction</h4>
              </div>
            </div>
            <div class="col-sm-4 financials-list">
              <h2>Payments</h2>
              <hr />
              <div *ngIf="data.finType != 'cash'">
                <h4>{{bi.financier_name}}</h4>
                <h4>{{bi.apr*100 | number : '1.0-2'}}% for {{bi.term}} Years</h4>
                <h4 class="financial-description">Loan Provider</h4>
              </div>
              <div *ngIf="data.finType != 'cash'">
                <h4>{{bi.first_year_loan | currency : 'USD' : 'symbol'
                  : '1.0' }} /Month</h4>
                <h4 class="financial-description">Year 1 Payments</h4>
              </div>
              <div *ngIf="data.finType != 'cash'">
                <h4>{{bi.after_first_year_loan | currency : 'USD' : 'symbol'
                  : '1.0' }} /Month</h4>
                <h4 class="financial-description">Years 2-{{bi.term}} Payments</h4>
              </div>
              <div *ngIf="data.finType != 'cash'">
                <h4>{{bi.avg_loan| currency : 'USD' : 'symbol': '1.0' }} /Month</h4>
                <h4 class="financial-description">Average Loan Payment</h4>
              </div>
              <div>
                <h4>{{(bi.new_bill - bi.avg_loan)| currency : 'USD' : 'symbol': '1.0' }} / Month</h4>
                <h4 class="financial-description">Utility Payment</h4>
              </div>
              <div *ngIf="data.finType != 'cash'">
                <h4 id="energy-bill-rate">{{bi.new_bill | currency : 'USD' : 'symbol': '1.0' }} / Month</h4>
                <h4 class="financial-description">New Energy Bill</h4>
              </div>
            </div>
            <div class="col-sm-4 financials-list">
              <h2>Savings & Benefits</h2>
              <hr />
              <div>
                <h4>{{bi.avg_monthly_savings | currency :
                  'USD' : 'symbol' : '1.0' }}</h4>
                <h4 class="financial-description">Avg. Monthly Savings</h4>
              </div>
              <div>
                <h4>{{bi.annual_savings | currency : 'USD' : 'symbol' : '1.0' }}</h4>
                <h4 class="financial-description">Avg. Annual Savings</h4>
              </div>
              <div *ngIf="data.finType=='cash'">
                <h4>{{bi.payback_period}} years</h4>
                <h4 class="financial-description">Payback Period</h4>
              </div>
              <div>
                <h4>{{bi.lifetime_savings | currency : 'USD' : 'symbol' : '1.0'}}</h4>
                <h4 class="financial-description">Lifetime Savings</h4>
              </div>
              <div *ngIf="data.finType != 'cash'">
                <h4>{{bi.irr}}%</h4>
                <h4 class="financial-description">IRR</h4>
              </div>
              <div *ngIf="data.finType == 'cash'">
                <h4>{{bi.roi}}%</h4>
                <h4 class="financial-description">ROI</h4>
              </div>
              <div>
                <h4>+{{bi.equipment.added_home_value | currency : 'USD' : 'symbol' : '1.0'}}</h4>
                <h4 class="financial-description">Home Value</h4>
              </div>
            </div>
          </div>
          <div class="row financial-row" style="margin-top:10px;">
            <div class="col-sm-12" *ngIf="bi.performanceRebateData.length != 0">
              <div *ngFor="let data of bi.performanceRebateData">
                <h2>{{data.name}}</h2>
                <hr/>
                <div class="property-tax">
                  <div class="bill-reduction">
                    <h4>{{data.incentiveType }}</h4>
                    <h4 class="financial-description">Incentive Type</h4>
                  </div>
                  <div class="bill-reduction">
                    <h4 class="val">{{data.applicableOn }}</h4>
                    <h4 class="financial-description">Payment Based Off of</h4>
                  </div>
                  <div class="bill-reduction">
                    <h4 class="val">{{data.endTerm }} Years</h4>
                    <h4 class="financial-description">Term</h4>
                  </div>
                  <div class="bill-reduction">
                    <h4 class="val" *ngIf="data.unit =='%'"> {{data.value * 100 | number : '1.0-0' }}{{data.unit}}</h4>
                    <h4 class="val" *ngIf="data.unit !='%'"> {{data.value/1000 | currency : 'USD' : 'symbol' : '1.0'}} {{data.unit}}</h4>
                    <h4 class="financial-description">Rate</h4>
                  </div>
                  <div class="bill-reduction">
                    <h4 class="val">{{data.estimatedTermsValue | currency : 'USD' : 'symbol'
                      : '1.0-0' }}</h4>
                    <h4 class="financial-description">Est. Total Value</h4>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <!--Utility table-->
      <!-- <section class="pdf-intro"
           *ngIf="bi.genability_data.preSolar && bi.genability_data.postSolar&&bi.genability_data.annual_saving&&bi.genability_data.cumulative_saving">
        <div class="financials-table">
          <div class="system-details-title">
            <div class="system-details-header">
              <h1 class="heading">Your Solar Offers</h1>
            </div>
            <div class="system-details-title-info">
              <h1 class="bid-rank">{{'Bid' | lang: componentLocation}} {{bi.bid_rank}}</h1>
              <div class="bid-type-title">
                <i class="pms-icon icon-Financials"></i>
                <h3>Financials</h3>
              </div>
            </div>
          </div>
          <hr class="dark-hr" />
          <h2 class="cashflow">Cashflow Table</h2>
          <table>
            <thead>
            <tr>
              <th scope="col">Year</th>
              <th scope="col">Annual Bill Pre Solar</th>
              <th scope="col">Annual Bill Post Solar</th>
              <th>Annual Cashflows</th>
              <th>Cumulative Cashflows</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let cash of bi.genability_data.preSolar; let indexNo = index;">
              <td>{{indexNo + 1}}</td>
              <td [ngClass]="{'negative-number': bi.genability_data.preSolar[indexNo].cost < 0}">
                {{bi.genability_data.preSolar[indexNo].cost > 0 ? (bi.genability_data.preSolar[indexNo].cost | currency : 'USD' : 'symbol' : '1.0-0') : '(' + (getAbsoluteVal(bi.genability_data.preSolar[indexNo].cost) | currency : 'USD' : 'symbol' : '1.0-0') + ')'}}
              </td>
              <td *ngIf="bi.genability_data.postSolar.length > indexNo"
                  [ngClass]="{'negative-number': bi.genability_data.postSolar[indexNo].cost < 0}">
                {{bi.genability_data.postSolar[indexNo].cost > 0 ? (bi.genability_data.postSolar[indexNo].cost | currency : 'USD' : 'symbol' : '1.0-0') : '(' + (getAbsoluteVal(bi.genability_data.postSolar[indexNo].cost) | currency : 'USD' : 'symbol' : '1.0-0') + ')'}}
              </td>
              <td *ngIf="bi.genability_data.annual_saving.length > indexNo"
                  [ngClass]="{'negative-number': bi.genability_data.annual_saving[indexNo + 1] < 0}">
                {{bi.genability_data.annual_saving[indexNo+1] > 0 ? (bi.genability_data.annual_saving[indexNo+1] | currency : 'USD' : 'symbol' : '1.0-0'): '(' + (getAbsoluteVal(bi.genability_data.annual_saving[indexNo+1]) | currency : 'USD' : 'symbol' : '1.0-0') + ')'}}
              </td>
              <td *ngIf="bi.genability_data.cumulative_saving.length > indexNo"
                  [ngClass]="{'negative-number': bi.genability_data.cumulative_saving[indexNo + 1] < 0}">
                {{bi.genability_data.cumulative_saving[indexNo + 1] > 0 ? (bi.genability_data.cumulative_saving[indexNo + 1] | currency : 'USD' : 'symbol' : '1.0-0'): '(' + (getAbsoluteVal(bi.genability_data.cumulative_saving[indexNo + 1]) | currency : 'USD' : 'symbol' : '1.0-0') + ')'}}
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </section> -->

      <!--Srec Data-->
      <section class="pdf-intro" *ngIf="bi.totalSrecRebates.length > 0">
        <div class="offer-information">
          <div class="system-details-title">
            <div class="system-details-header">
              <h1 class="heading">Your Offers</h1>
            </div>
            <div class="system-details-title-info">
              <h1 class="bid-rank">{{'Bid' | lang: componentLocation}} {{bi.bid_rank}}</h1>
              <div class="bid-type-title">
                <i class="pms-icon icon-Financials"></i>
                <h3>Financials</h3>
              </div>
            </div>
          </div>
          <hr class="dark-hr" />
          <h2 >SREC</h2>
          <div class="row srec-row">
            <div class="col-sm-7" style="text-align:center">
              <img [src]="'srec'| lang: componentLocation" alt="Srec rebate icon" style="max-width:365px; max-height:196px;">
            </div>
            <div class="col-sm-5" style="margin:auto">
              <div class="d-flex align-items-baseline">
                <div class="material-icons srec1"></div>
                <p>States with a solar carve-out and SREC market.</p>
              </div>
              <div class="d-flex align-items-baseline">
                <div class="material-icons no-srec1"></div>
                <p>States with no SREC market but have areas eligible for an outside SREC market.</p>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div class="row srec-description">
            <h3 class="offer-description">{{bi.totalSrecRebates[0].name}}</h3>
            <div class="col-sm-12" *ngIf="bi.totalSrecRebates[0].applicableOn == 'Upfront'">
              <div class="srec-data">
                <div>
                  <h3>{{bi.totalSrecRebates[0].estimatedTermsValue | currency : 'USD' : 'symbol' : '1.0-0' }}</h3>
                  <h3 class="offer-description">Lump Sum Upfront SREC Payment</h3>
                </div>
              </div>
            </div>
            <div class="col-sm-12 p-0" *ngIf="bi.totalSrecRebates[0].valueType == 'Annual' && bi.totalSrecRebates[0].isCombo == true">
              <div class="srec-data">
                <div>
                  <h3>{{bi.totalSrecRebates[0].productionData[0].estimatedValue | currency : 'USD' : 'symbol' : '1.0' }}</h3>
                  <h3 class="offer-description">Fixed SREC Value for {{bi.totalSrecRebates[0].productionData[0].startTerm}} - {{bi.totalSrecRebates[0].productionData[0].endTerm}} Year Term</h3>
                </div>
                <div>
                  <h3>{{bi.totalSrecRebates[0].productionData[0].production | number:'1.0-0'}} kWh</h3>
                  <h3 class="offer-description">Estimated Production over Term</h3>
                </div>
                <div>
                  <h3>{{bi.totalSrecRebates[0].productionData[0].estimatedProductionValue | currency : 'USD' : 'symbol' : '1.0' }}</h3>
                  <h3 class="offer-description">Estimated Term SREC Value</h3>
                </div>
              </div>
              <div class="srec-data">
                <div>
                  <h3>{{bi.totalSrecRebates[0].productionData[1].estimatedValue | currency : 'USD' : 'symbol' : '1.0' }}</h3>
                  <h3 class="offer-description">Fixed SREC Value for {{bi.totalSrecRebates[0].productionData[1].startTerm}} - {{bi.totalSrecRebates[0].productionData[1].endTerm}} Year Term</h3>
                </div>
                <div>
                  <h3>{{bi.totalSrecRebates[0].productionData[1].production | number:'1.0-0'}} kWh</h3>
                  <h3 class="offer-description">Estimated Production over Term</h3>
                </div>
                <div>
                  <h3>{{bi.totalSrecRebates[0].productionData[1].estimatedProductionValue | currency : 'USD' : 'symbol' : '1.0' }}</h3>
                  <h3 class="offer-description">Estimated Term SREC Value</h3>
                </div>
              </div>
              <div class="srec-data">
                <div>
                  <h3>{{bi.totalSrecRebates[0].productionComboData.averageSrecVal | currency : 'USD' : 'symbol' : '1.0' }}</h3>
                  <h3 class="offer-description">Average SREC Value over Terms</h3>
                </div>
                <div>
                  <h3>{{bi.totalSrecRebates[0].productionComboData.totalProductionVal | currency : 'USD' : 'symbol' : '1.0' }}</h3>
                  <h3 class="offer-description">Total Estimated Production over Terms</h3>
                </div>
                <div>
                  <h3>{{bi.totalSrecRebates[0].productionComboData.estimatedProductionVal | currency : 'USD' : 'symbol' : '1.0' }}</h3>
                  <h3 class="offer-description">Total Estimated SREC Value</h3>
                </div>
              </div>
            </div>
            <div class="col-sm-12 p-0" *ngIf="bi.totalSrecRebates[0].applicableOn == 'Brokerage' || bi.totalSrecRebates[0].valueType == 'Market'">
              <div class="srec-data">
                <div>
                  <h3>{{bi.totalSrecRebates[0].value * 1000 | currency : 'USD' : 'symbol' : '1.0-0' }}</h3>
                  <h3 class="offer-description">Current Market Value of 1 SREC</h3>
                </div>
                <div>
                  <h3>{{bi.totalSrecRebates[0].estimatedTermsValue | currency : 'USD' : 'symbol' : '1.0-0' }}</h3>
                  <h3 class="offer-description">Estimated First Year SREC Value</h3>
                </div>
              </div>
            </div>
            <div class="col-sm-12 p-0" *ngIf="bi.totalSrecRebates[0].applicableOn == 'Annuity' && bi.totalSrecRebates[0].isCombo == false">
              <div class="srec-data">
                <div>
                  <h3>{{bi.totalSrecRebates[0].value * 1000 | currency : 'USD' : 'symbol' : '1.0-0' }}</h3>
                  <h3 class="offer-description">Fixed SREC Value for {{bi.totalSrecRebates[0].metadata.filters.endTerm}} Year Term</h3>
                </div>
                <div>
                  <h3>{{bi.totalSrecRebates[0].totalProduction | number:'1.0-0'}} KWh</h3>
                  <h3 class="offer-description">Estimated Production over Term</h3>
                </div>
                <div>
                  <h3>{{bi.totalSrecRebates[0].estimatedTermsValue | currency : 'USD' : 'symbol' : '1.0-0' }}</h3>
                  <h3 class="offer-description">Estimated Term SREC Value</h3>
                </div>
              </div>
            </div>
            <div class="col-sm-12 p-0">
              <p>
                *NOTE: The tax credit represented on this {{'bid' | lang: componentLocation}} is wholly dependent on your personal tax liability and it is
                recommended that you speak with an accountant on your ability to claim it. You will be responsible for
                payment or financing of the tax credit prior to receiving it.
              </p>
              <p>
                **NOTE: The value is representative of the current market value of SRECs in New Jersey. You will be able
                to
                sell your SRECs for 10 years to a broker or on the open market at whatever their value ia as the time time
                you decide to sell. The value is subject to change due to market fluctuation,
                which would effect your realized savings. Legislation changes could also alter or end the SREC program in
                New
                Jersey, which would effect your realized savings as well.
              </p>

            </div>
          </div>
        </div>
      </section>

      <!--Installer Information-->
      <section class="pdf-intro">
        <div class="installer-information">
          <div class="system-details-title">
            <div class="system-details-header">
              <h1 class="heading">Your Offers</h1>
            </div>
            <div class="system-details-title-info">
              <h1 class="bid-rank">{{'Bid' | lang: componentLocation}} {{bi.bid_rank}}</h1>
              <div class="bid-type-title">
                <i class="pms-icon icon-Installer"></i>
                <h3>Installer</h3>
              </div>
            </div>
          </div>
          <hr class="dark-hr" />
          <div class="installer-view">
            <br>
            <div class="row">
              <div class="col-sm-5" style="margin: auto">
                <div class="installer-image-div">
                  <img [src]="bi.installer.logo_url" alt="Installer logo image" class="installer-image">
                </div>
                <div class="installer-title">
                  <h4>{{bi.installer.name}}</h4>
                </div>
                <div class="rating-installers">
                  <img *ngIf="bi.installer.review_image" [src]="bi.installer.review_image" alt="Installer rating image" class="installer-image">
                </div>
              </div>
              <div class="col-sm-7 installer-about">
                <h1 class="about-d">About</h1>
                <p *ngIf="bi.installer.about != null">{{ bi.installer.about | slice: 0:500 }}{{bi.installer.about.length > 500 ? ' ...view more detail on dashboard.' : ''}}</p>
              </div>
            </div>
            <br />
            <br />
            <h1 class="ins-det">Details</h1>
            <br />
            <div class="row installer-info">
              <div class="col-sm-5 installer-details">
                <div>
                  <h3 class="data">{{bi.installer.experience}}</h3>
                  <h3 class="ins-inf">Year Established</h3>
                </div>
                <div>
                  <h3 class="data">{{bi.installer.installations}}</h3>
                  <h3 class="ins-inf">Installations</h3>
                </div>
                <div>
                  <h3 class="data">{{bi.installer.workmanship_warranty}} years</h3>
                  <h3 class="ins-inf">Workmanship Warranty</h3>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="qualification-list">
                  <h5 >Qualifications</h5>
                  <div class="list-q">
                    <div class="tick-icon">
                      <i class="pms-icon icon-check"></i>
                    </div>
                    <div class="li">Vetted contractors licence & fully insured.</div>
                  </div>
                  <div class="list-q">
                    <div class="tick-icon">
                      <i class="pms-icon icon-check"></i>
                    </div>
                    <div class="li">NABCEP certified.</div>
                  </div>
                  <div class="list-q">
                    <div class="tick-icon">
                      <i class="pms-icon icon-check"></i>
                    </div>
                    <div class="li">Excellent reputation for workmanship and customer service.</div>
                  </div>
                  <div class="list-q">
                    <div class="tick-icon">
                      <i class="pms-icon icon-check"></i>
                    </div>
                    <div class="li">Ability to provide references and testimonials</div>
                  </div>
                </div>

              </div>
            </div>
            <br />
            <h1 *ngIf="bi.installer.image1||bi.installer.image2||bi.installer.image3||bi.installer.image4"
                class="ins-det installtion-title">Installations</h1>
            <br />
            <div style="display: flex;" class="installation-images">
              <div style="margin: auto;">
                <img *ngIf="bi.installer.image1" [src]="bi.installer.image1" alt="Installation image">
                <img *ngIf="bi.installer.image2" [src]="bi.installer.image2" alt="Installation image">
                <img *ngIf="bi.installer.image3" [src]="bi.installer.image3" alt="Installation image">
                <img *ngIf="bi.installer.image4" [src]="bi.installer.image4" alt="Installation image">
              </div>
            </div>
          </div>
        </div>
      </section>

       <!--Battery Bids-->
      <section class="pdf-intro" *ngIf="bi?.batteryData?.length > 0">
        <div class="battery-view">
          <div class="system-details-title">
            <div class="system-details-header">
              <h1 class="heading">Your Offers</h1>
            </div>
            <div class="system-details-title-info">
              <h1 class="bid-rank">{{'Bid'| lang: componentLocation}} {{bi.bid_rank}}</h1>
              <div class="bid-type-title">
                <i class="pms-icon icon-Battery"></i>
                <h3>Battery</h3>
              </div>
            </div>
          </div>
          <hr />
          <h3 class="installer-title-about">Add Battery Storage</h3>
          <h4>{{'partner' | lang : componentLocation}}
            engineers have determined that these battery options would be the best fit for your
            electricity
            usage and PV system</h4>
          <div class="row">
            <div *ngFor="let batt of bi?.batteryData; let ind = index" [class]="'battery-'+ (ind + 1)" [ngClass]="{'col-sm-4': bi.batteryData.length > 2, 'col-sm-6': bi.batteryData.length == 2, 'col-sm-12': bi.batteryData.length < 2}">
              <div class="battery-col">
                <h3 class="battery-title">{{batt.name}}</h3>
                <hr />
                <h1>+ {{(data.finType == 'cash' ? (batt.net_price | currency:'USD':'symbol':'2.0') : (batt.avg_loan | currency:'USD':'symbol':'2.0') + '/Month')}}</h1>
                <img style="width: 100%; max-width: 100px; height:80px" [src]="batt.imageLink" alt="Battery image">
                <h4>Specifications</h4>
                <hr />
                <div>
                  <h3 >{{batt.capacity}} kW<span class="specification-span">(9.8kWh)</span></h3>
                  <h3 class="battery-description">Power (capacity)</h3>
                </div>
                <h4>Price</h4>
                <hr />
                <div>
                  <h3>{{batt.gross_price | currency:'USD':'symbol':'2.0'}}</h3>
                  <h3 class="battery-description">Gross Price</h3>
                </div>
                <div>
                  <h3>{{batt.cash_discount | currency:'USD':'symbol':'2.0'}}</h3>
                  <h3 class="battery-description">{{data.finType | titlecase }} Discount</h3>
                </div>
                <div *ngIf="batt.incentives.federal_tax_credit_total_value">
                  <h3>
                    {{batt.incentives.federal_tax_credit_total_value | currency : 'USD' : 'symbol' : '1.0' }}</h3>
                  <h3 class="battery-description">Federal Tax Credit</h3>
                </div>
                <div *ngIf="batt.incentives.state_tax_credit_total_value">
                  <h3>
                    {{batt.incentives.state_tax_credit_total_value | currency : 'USD' : 'symbol' : '1.0' }}</h3>
                  <h3 class="battery-description">State Tax Credit</h3>
                </div>
                <div *ngIf="batt.incentives.state_rebate_total_value">
                  <h3>
                    {{batt.incentives.state_rebate_total_value | currency : 'USD' : 'symbol' : '1.0' }}</h3>
                  <h3 class="battery-description">State Rebates</h3>
                </div>
                <div *ngIf="batt.incentives.utility_rebate_total_value">
                  <h3>
                    {{batt.incentives.utility_rebate_total_value | currency : 'USD' : 'symbol' : '1.0' }}</h3>
                  <h3 class="battery-description">Utility Rebates</h3>
                </div>
                <div>
                  <h3>{{batt.net_price | currency:'USD':'symbol':'2.0'}}</h3>
                  <h3 class="battery-description">Net Price</h3>
                </div>
                <br />
                <h4>Warranty</h4>
                <hr />
                <div>
                  <h3>{{batt.manufacturer_warranty}} Years / {{batt.capacityWarranty}}%
                    Capacity</h3>
                  <h3 class="battery-description">Manufacturer</h3>
                </div>
                <div>
                  <h3>{{batt.installer_warranty}} Years (Parts & Labor)</h3>
                  <h3 class="battery-description">{{batt.installer_name.name}}</h3>
                </div>
                <br />
              </div>
            </div>
          </div>

        </div>
      </section>

    </div>
  <!--End Solar Offers Section-->



  <!--Start Footer Section-->
  <section>
    <div class="foot" [ngClass]="{'foot-lg' : data.partner_type =='LG-Solar'}">
      <p>Contact <span>{{data.manager.name}}</span></p>
      <div class="d-flex align-items-center flex-direction-row">
        <i class="footer-icon icon-call"></i>
        <p>{{data.manager.phone}}</p>
      </div>
      <div class="d-flex align-items-center flex-direction-row">
        <i class="footer-icon icon-mail"></i>
        <p>{{data.manager.email}}</p>
      </div>
      <img [src]="'footer_logo'| lang: componentLocation" alt="Brand logo" class="foot-logo" style="width: 72px;height: 36px;">
    </div>
  </section>
  <!--End Footer Section-->
</div>
