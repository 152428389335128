import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SolarPdfComponent } from './solar-pdf/solar-pdf.component';
import {BatteryPdfComponent} from './battery-pdf/battery-pdf.component';
import {LanguagePipeModule} from './utils/language-pipe/language-pipe.module';
import {SharedModule} from './shared/shared.module';
import { HubbleSolarPdfComponent } from './hubble-solar-pdf/hubble-solar-pdf.component';
import { HubbleBatteryPdfComponent } from './hubble-battery-pdf/hubble-battery-pdf.component';

@NgModule({
  declarations: [
    AppComponent,
    SolarPdfComponent,
    BatteryPdfComponent,
    HubbleSolarPdfComponent,
    HubbleBatteryPdfComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    LanguagePipeModule,
    SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
