import { Pipe, PipeTransform } from '@angular/core';
import {LANG} from '../../configuration/language-config';

@Pipe({name: 'lang'})

export class LanguagePipe implements PipeTransform {
  transform(value: string, location: string): string {
    if (location && value) {
      return this.getElement(location)[value];
    }
    return '';
  }
  getElement = (loc: string) => {
    const tempLoc: any = loc.split('>');
    let tempObj = LANG.data;
    tempLoc.map((r) => {
      tempObj = tempObj[r];
    });
    return tempObj;
  }
}
