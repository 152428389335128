import { Component, OnInit } from '@angular/core';
import {CDN_URL} from '../app.constants';
import {AppService} from '../app.service';
import {LANG} from '../configuration/language-config';
import {COLORS} from '../configuration/colors-config';

@Component({
  selector: 'app-battery-pdf',
  templateUrl: './battery-pdf.component.html',
  styleUrls: ['./battery-pdf.component.scss']
})
export class BatteryPdfComponent implements OnInit {
  cdnURL = CDN_URL;
  data: any;
  public componentLocation = 'text';
  constructor( public appService: AppService) { }

  ngOnInit(): void {
    this.data = JSON.parse(sessionStorage.getItem('pdfData'));
    console.log(this.data);
    if (this.data.hasOwnProperty('color')){
     this.appService.setProps(this.data.color);
    } else {
      this.appService.setColors(this.data.partner_type);
    }
    this.appService.setLanguage(this.data.partner_type);
  }

}
