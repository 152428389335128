<div class="pdf-output" *ngIf="data">
  <section class="pdf-intro">
    <div class="pdf-title">
      <div class="header-image" [style.backgroundImage]="'url('+ data?.metaData?.lang?.images?.home_bg +')'"
        aria-label="Brand image" title="Brand image">
        <div class="bottom-section">
          <div class='partner-meta'>
            <img [src]="data?.metaData?.lang?.images?.logo" alt="Brand logo image" class="logo-image" /> |
            <p>{{data?.metaData?.lang?.metaTitle}}</p>
          </div>
          <h1 class="offers">{{data?.metaData?.lang?.title}}</h1>
          <p class="for-client">
            For <span>{{data?.metaData?.user?.name}}</span>
          </p>
        </div>
        <div class="cart items">
          <div class="main bg-white">
            <div class="p-[24px] flex flex-col gap-[16px] w-full">
              <!-- Section Title -->
              <h2 class="ma-b">
                Detailed Quote Breakdown
              </h2>

              <!-- Breakdown Sections -->
              <div *ngFor="let brKey of breakdownKeys; let i = index" class="breakdown-section quote-section">
                <!-- Breakdown Header -->
                <div class="flex flex-col gap-[8px]">
                  <div class="breakdown-header text-color-darkgray font-ActualM text-[14px]">
                    <div class="section-title">
                      <ng-container *ngIf="brKey === 'installation_cost'; else equipmentCost">
                        <ng-container *ngIf="data.cart.permitting_flag">
                          Part, Labor & Permitting
                        </ng-container>
                        <ng-container *ngIf="!data.cart.permitting_flag">
                          Part & Labor
                        </ng-container>
                      </ng-container>
                      <ng-template #equipmentCost>
                        <ng-container *ngIf="data.cart['equipment_cost']?.length === 0 else hasData">
                          <p *ngIf="data.metaData.includedLineItem?.name && data.metaData.includedLineItem?.coupon">
                            Equipment Costs
                          </p>
                        </ng-container>
                        <ng-template #hasData>
                          Equipment Costs
                        </ng-template>
                      </ng-template>
                    </div>
                  </div>

                  <!-- Breakdown Items -->
                  <div class="breakdown" *ngIf="brKey !== 'installation_cost'">
                    <ng-container *ngIf="data.cart['equipment_cost']?.length === 0 else hasDataItems">
                      <li  *ngIf="data.metaData.includedLineItem?.name && data.metaData.includedLineItem?.coupon" class="item item-border-bottom" >
                        <span class="title"> {{data.metaData.includedLineItem?.name}} </span>
                        <span class="price"> Included </span>
                      </li>
                    </ng-container>
                    <ng-template #hasDataItems>
                      <ul class="items">

                        <li  *ngIf="data.metaData.includedLineItem?.name && data.metaData.includedLineItem?.coupon" class="item" >
                          <span class="title"> {{data.metaData.includedLineItem?.name}} </span>
                          <span class="price"> Included </span>
                        </li>

                        <ng-container *ngFor="let breakdownItemInner of data.cart['equipment_cost'].breakdown;let j = index">
                          <ng-container *ngTemplateOutlet="BreakdownLineRenderer; context: { lineData: breakdownItemInner, isBold: false, isHighlight: false,
                            border: (j === data.cart['equipment_cost'].breakdown.length - 1 && !isNotArray(data.cart['equipment_cost'].coupon)), boldBorder: false }">
                          </ng-container>
                        </ng-container>

                        <!-- Coupon -->
                        <li *ngIf="isNotArray(data.cart['equipment_cost'].coupon)" class="item item-border-bottom coupon" >
                          <span class="title"> {{data.cart['equipment_cost'].coupon.display_name}} </span>
                          <span class="price"> -{{ data.cart['equipment_cost'].coupon.cost | currency: 'USD' : 'symbol' : '1.2-2' }} </span>
                        </li>

                        <!-- Equipment Subtotal -->

                      <li *ngIf="data.cart['equipment_cost'].equipment_cost_subtotal" class="item" >
                        <span class="title"> {{data.cart['equipment_cost'].equipment_cost_subtotal.display_name }} </span>
                        <span class="price" > {{ data.cart['equipment_cost'].equipment_cost_subtotal.cost | currency: 'USD' : 'symbol' : '1.2-2' }} </span>
                      </li>

                        <!-- Equipment Sales Tax -->

                        <li *ngIf="data.cart['equipment_cost'].sales_tax != null" class="item item-border-bottom" >
                          <span class="title"> {{data.cart['equipment_cost'].sales_tax.display_name }} </span>
                          <span class="price" *ngIf="(data.cart['equipment_cost'].sales_tax.cost === 0 && data.cart.hasOwnProperty('migrated') && data.cart.migrated) else salesTax">
                            Included if applicable
                          </span>
                          <ng-template #salesTax>
                            <span class="price">
                              {{ data.cart['equipment_cost'].sales_tax.cost | currency: 'USD' : 'symbol' : '1.2-2' }}
                            </span>
                          </ng-template>
                        </li>

                        <li *ngIf="data.cart['equipment_cost'].sales_tax === null" class="item item-border-bottom" >
                          <span class="title"> {{data.cart['equipment_cost'].sales_tax.display_name }} </span>
                          <span class="price" > --- </span>
                        </li>

                        <!-- Equipment Total -->

                        <li *ngIf="data.cart['equipment_cost'].equipment_cost_total" class="item item-border-bottom dark" >
                          <span class="title"> {{data.cart['equipment_cost'].equipment_cost_total.display_name }} </span>
                          <span class="price" > {{ data.cart['equipment_cost'].equipment_cost_total.cost | currency: 'USD' : 'symbol' : '1.2-2' }} </span>
                        </li>
                      </ul>
                    </ng-template>

                  </div>
                  <div class="breakdown" *ngIf="brKey === 'installation_cost'">
                    <ul class="items">

                      <ng-container *ngFor="let breakdownItemInner of data.cart['installation_cost'].breakdown;let j = index">
                        <ng-container *ngTemplateOutlet="BreakdownLineRenderer; context: { lineData: breakdownItemInner, isBold: false, isHighlight: false,
                          border: (j === data.cart['installation_cost'].breakdown.length - 1 && !isNotArray(data.cart['installation_cost'].coupon)), boldBorder: false }">
                        </ng-container>
                      </ng-container>

                      <!-- Coupon -->

                      <li *ngIf="isNotArray(data.cart['installation_cost'].coupon)" class="item item-border-bottom coupon" >
                        <span class="title"> {{data.cart['installation_cost'].coupon.display_name}} </span>
                        <span class="price"> -{{ data.cart['installation_cost'].coupon.cost | currency: 'USD' : 'symbol' : '1.2-2' }} </span>
                      </li>

                      <!-- Installation Subtotal -->

                      <li *ngIf="data.cart['installation_cost'].installation_cost_subtotal" class="item" >
                        <span class="title"> {{data.cart['installation_cost'].installation_cost_subtotal.display_name }} </span>
                        <span class="price" > {{ data.cart['installation_cost'].installation_cost_subtotal.cost | currency: 'USD' : 'symbol' : '1.2-2' }} </span>
                      </li>

                       <!-- Platform Fee -->

                       <li *ngIf="data.cart['installation_cost'].platform_fee" class="item" >
                        <span class="title"> {{data.cart['installation_cost'].platform_fee.display_name }} </span>
                        <span class="price" > {{ data.cart['installation_cost'].platform_fee.cost | currency: 'USD' : 'symbol' : '1.2-2' }} </span>
                      </li>

                      <!-- Installation Sales Tax -->

                      <li *ngIf="data.cart['installation_cost'].sales_tax" class="item item-border-bottom" >
                        <span class="title"> {{data.cart['installation_cost'].sales_tax.display_name }} </span>
                        <span class="price" > {{ data.cart['installation_cost'].sales_tax.cost | currency: 'USD' : 'symbol' : '1.2-2' }} </span>
                      </li>

                      <!-- Installation Total -->

                      <li *ngIf="data.cart['installation_cost'].installation_cost_total" class="item item-border-bottom dark" >
                        <span class="title"> {{data.cart['installation_cost'].installation_cost_total.display_name }} </span>
                        <span class="price" > {{ data.cart['installation_cost'].installation_cost_total.cost | currency: 'USD' : 'symbol' : '1.2-2' }} </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Quote Total -->
              <div class="total">
                <span>
                  Quote Total
                </span>
                <span>
                  {{ data.cart.grand_total | currency: 'USD' : 'symbol' : '1.2-2' }}
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="mid-section">
        <div class="header-box">
          <h1 class="tagline">{{data.metaData.lang.tagLine}}</h1>
          <p class="tagline-sub">{{data.metaData.lang.taglineSub}}</p>
          <div class="branding">
            <sub>Powered By</sub>
            <img [src]="data?.metaData?.lang?.electrumLogo" alt="Brand logo image" class="logo-image" />
          </div>
        </div>
        <div class="five-boxes">
          <div class="box-single" *ngFor="let box of data?.metaData?.lang?.homeBoxes">
            <i class="{{box.icon}}"></i>
            <h3 class="">{{box.title}}</h3>
            <p>{{box.content}}</p>
          </div>
        </div>
      </div>
      <div class="ratings">
        <div class="rating-score">
          <h2>{{data.metaData.ratingData.recommended}}%</h2>
          <p>of Customers Recommend Our Service</p>
          <p class="source">Based on NPS survey feedback collected in 2024.</p>
        </div>
        <div class="rating-score">
          <h2>{{data.metaData.ratingData.score}}
            <span>out of 5</span>
          </h2>
          <p>Customer Satisfaction Score</p>
          <p class="source">Based on ratings from customers after their installation experience in 2024.</p>
        </div>
      </div>
    </div>
  </section>
  <!-- Page 2 -->
  <section class="item-listing">
    <div class="top-intro">
      <h3>Your EV Charger Installation Quote</h3>
      <p>Installation Address: <span>{{data.metaData.user.address}}</span></p>
    </div>
    <div class="items-container">
      <div class="item header">
        <div class="item-details">Product / Service</div>
        <div class="amount">Amount</div>
      </div>
      <!-- Line items -->
      <div *ngFor="let item of data.quotes.product_services.line_items; let i = index" class="item"
        [ngClass]="{'hidden': item.cost === 0}">
        <div class="item-details">
          <p class="item-title">{{item.display_name}}</p>
          <ul class="item-desc">
            <li *ngFor="let point of item.description">{{point}}</li>
          </ul>
        </div>
        <div *ngIf="i === 0 && item.cost === null && (data.metaData.lang.partner === 'Honda' || data.metaData.lang.partner === 'Acura') then noCord else cost">
        </div>
        <ng-template #cost>
          <div class="amount">
            {{item.cost | currency: 'USD' : 'symbol' : '1.2-2'}}
          </div>
        </ng-template>
        <ng-template #noCord>
          <div *ngIf="data.metaData.includedLineItem?.coupon && !data.quotes.cord_flag then couponAdded else noCoupon"></div>
          <ng-template #couponAdded>
            <div class="include-coupon amount">
              <p class="text">
                 Included
              </p>
              <p class="text-with-icon">
                <span class="icon-multi-project-active"></span>
                Coupon applied
              </p>
            </div>
          </ng-template>
          <ng-template #noCoupon>
            <div class="amount">
              Included with Coupon
            </div>
          </ng-template>
        </ng-template>
      </div>
      <!-- Custom Line items -->

      <div *ngFor="let item of data.quotes.product_services.custom_line_items" class="item"
        [ngClass]="{'hidden': item.cost === 0}">
        <div class="item-details">
          <div class="item-title" [innerHTML]="item.display_name"></div>
          <div class="item-desc item-container">
            <p class="cust-line-title"> {{item.metadata.tittle}}</p>
            <ul class="item-desc">
              <li *ngFor="let point of item.description">
                  <div [innerHTML]="point"></div>
                  <!-- {{point}} -->
              </li>
            </ul>
          </div>

        </div>
        <div class="amount">{{item.cost | currency: 'USD' : 'symbol' : '1.2-2'}}</div>
      </div>

      <!-- Other items -->

      <div *ngFor="let item of data.quotes.product_services.other" class="item" [ngClass]="{'hidden': item.cost === 0}">
        <div class="item-details">
          <p class="item-title">{{item.display_name}}</p>
          <ul class="item-desc">
            <li *ngFor="let point of item.description">{{point}}</li>
          </ul>
        </div>
        <div class="amount">{{item.cost | currency: 'USD' : 'symbol' : '1.2-2'}}</div>
      </div>

      <!-- Addons items -->
      <div *ngIf="data.quotes.is_hems_added">
        <div *ngFor="let item of data.quotes.product_services.addon" class="item" [ngClass]="{'hidden': item.cost === 0}">
          <div class="item-details">
            <p class="item-title">{{item.display_name}}</p>
            <ul class="item-desc">
              <li *ngFor="let point of item.description">{{point}}</li>
            </ul>
          </div>
          <div class="amount">{{item.cost | currency: 'USD' : 'symbol' : '1.2-2'}}</div>
        </div>
      </div>

      <div class="item sub-total-section">
        <div class="p-break sub-total">
          <span>Subtotal </span>
          <span class="amount">{{data.quotes.subtotal | currency: 'USD' : 'symbol' : '1.2-2'}}</span>
        </div>

        <div class="p-break sales-tax" *ngIf="data.cart['installation_cost'].platform_fee">
          <span>{{data.cart['installation_cost'].platform_fee.display_name }} </span>
          <span class="amount">{{data.cart['installation_cost'].platform_fee.cost | currency: 'USD' : 'symbol' : '1.2-2'}}</span>
        </div>

        <div class="p-break promotion" *ngIf="data.cart.coupon_total > 0">
          <span>Promotions/Coupons</span>
          <span class="amount">-{{data.cart.coupon_total | currency: 'USD' : 'symbol' : '1.2-2'}}</span>
        </div>

        <div class="p-break sales-tax" *ngIf="data.cart.sales_tax">
          <span>Sales Tax</span>
          <span class="amount">{{data.cart.sales_tax | currency: 'USD' : 'symbol' : '1.2-2'}}</span>
        </div>
      </div>

      <div class="item total-section">
        <div class="p-break total">
          <span>Total</span>
          <span class="amount">{{data.cart.total | currency: 'USD' : 'symbol' : '1.2-2'}}</span>
        </div>
        <div class="p-break refundable-deposit" *ngIf="data.metaData.lang.partner === 'Hyundai'">
          <div class="p-break remaining-balance">
            <span>Refundable Deposit (Already Paid)</span>
            <span class="amount">-{{1 | currency: 'USD' : 'symbol' : '1.2-2'}}</span>
          </div>
          <div class="p-break remaining-balance">
            <span>Remaining Balance</span>
            <span class="amount">-{{(data.cart.total - 1) | currency: 'USD' : 'symbol' : '1.2-2'}}</span>
          </div>
        </div>

      </div>

      <div class="item footer">
        <div class="pp">
          <h5>
            <i class="icon-Protection plan-icon"></i>
            {{data.metaData.lang.protectionPlan.name}}
          </h5>
        </div>
        <div class="pp-by">
          <p>{{data.metaData.lang.protectionPlan.title}}</p>
        </div>
        <p class="warrnty">{{data.metaData.lang.protectionPlan.description}}</p>
      </div>
    </div>

  </section>

  <section class="last-page">

    <div class="lp-container">
      <header class="header">
        <h1>Your EV Charger Installation Quote</h1>
        <p><span>Installation Address:</span> {{data.metaData.user.address}}</p>
      </header>
      <div class="lp-content">
        <div class="notes-section" *ngIf="data.metaData.projectData.additional_notes">
          <div class="additional-notes">
            <h2>Additional Notes from <span>{{data.metaData.installerInfo.name}}</span></h2>
            <p>{{data.metaData.projectData.additional_notes}}</p>
          </div>

          <div class="additional-repairs" *ngIf="data.metaData.projectData.customer_notes">
            <h2>Additional Repairs Required</h2>
            <ul>
              <li>{{data.metaData.lang.page3.additional_repair}} <span>{{data.metaData.installerInfo.name}}:</span></li>
            </ul>
            <p>{{data.metaData.projectData.customer_notes}}</p>
          </div>
        </div>

        <div class="warranty-img" *ngIf="data.metaData.lang.partner === 'Honda' || data.metaData.lang.partner === 'Acura'">
          <img [src]="data?.metaData?.lang?.images?.page3_img" alt="warranty image" class="wrnty-image" />
          <div class="warranty-logo">
            <img [src]="data?.metaData?.lang?.images?.logo" alt="Brand logo image" class="logo-image" />
            <p>
              <span>{{data.metaData.lang.page3.warranty.title1}}</span> {{data.metaData.lang.page3.warranty.title2}} <span>{{data.metaData.lang.page3.warranty.title3}}</span> {{data.metaData.lang.page3.warranty.title4}}
            </p>

          </div>
        </div>

        <div class="warranty-section">
          <p class="ins-warr"><span>{{data.metaData.installerInfo.name}}</span> {{data.metaData.lang.page3.inst_warranty.p1}} <span>{{data.metaData.lang.page3.inst_warranty.p2}}</span> {{data.metaData.lang.page3.inst_warranty.p3}} <span>{{data.metaData.lang.page3.warranty.title1}}</span> {{data.metaData.lang.page3.warranty.title2}} <span>{{data.metaData.lang.page3.warranty.title3}}</span> {{data.metaData.lang.page3.warranty.title4}} </p>
          <p class="note">{{data.metaData.lang.page3.notIncluded}}</p>
        </div>

        <div class="expert-info">
          <div class="expert-card">
            <p class="y-exp">Your local installation expert</p>
            <div class="inst-meta">
              <div class="inst-img">
                <img class="i-img" [src]="data.metaData.installerInfo.logo" alt="Test Demo Vendor - KeepLogo">
              </div>
              <div class="inst-cred">
                <p class="io-name">{{data.metaData.installerInfo.name}}</p>
                <p class="i-phn">{{data.metaData.installerInfo.phone}}</p>
              </div>
            </div>
          </div>
          <div class="ex-l">
            <p>
              <span>{{data.metaData.lang.page3.installerWarranty.title1}},</span>
              {{data.metaData.lang.page3.installerWarranty.title2}} <span>
                {{data.metaData.lang.page3.installerWarranty.title3}}</span>
            </p>
          </div>
        </div>

        <div class="rebates-section">
          <div class="rebates-top">
            <div class="rebates-header">
              <h2>Potential Rebates and Incentives</h2>
              <p>You may be eligible for the following incentives that were matched to your project.</p>
            </div>
            <div class="rebates-utiliy">
              <p class="utility"><span>Your utility:</span> {{data.metaData?.selectedUtility.utilityProviderName ? data.metaData?.selectedUtility.utilityProviderName : data.metaData?.selectedUtility.lse_name }}</p>
              <p class="note"><span>Note:</span> Incentives may be based on your utility. Ensure your utility is correct
                for accurate information.</p>
            </div>
          </div>

          <div class="rebates-boxes">
            <div class="box-wrapper-reb"  [ngClass]="{'bwr-2': utilityDescription.length > 1}">
              <div class="reb-t" *ngFor="let reb of utilityDescription;">
                <div class="details">
                  <p class="title">
                    {{reb.title}}
                  </p>
                  <p class="sub">
                    {{reb.text}}
                  </p>
                </div>
                <div class="price-box">
                  <p class="price">
                    {{reb.cost | currency:'USD' : 'symbol' : '1.0-0'}}
                  </p>
                  <p class="cal-a">
                    Calculated Amount*
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="footer-disc">
          {{data.metaData.lang.page3.incentiveDisclaimer}}
        </div>
      </div>

    </div>
    <div class="bottom-branding">
      <div class="branding">
        <sub>Powered By</sub>
        <img [src]="data?.metaData?.lang?.electrumLogo" alt="Brand logo image" class="logo-image" />
      </div>
    </div>
  </section>
  <!--Start Footer Section-->
  <!-- <section>
    <div class="foot">
      Page <span class="pageNumber"></span> of <span class="totalPages"></span>
    </div>
  </section> -->
</div>


<!-- Cart line item renderer -->
<ng-template #BreakdownLineRenderer let-lineData="lineData" let-isHighlight="isHighlight" let-isBold="isBold"
  let-border="border" let-dark="dark">
  <li *ngIf="lineData.length !== 0" class="item" [ngClass]="{ 'item-border-bottom': border,
      'dark': dark }">
    <span class="title">
      {{ lineData.display_name }}
    </span>
    <span class="price" *ngIf="lineData.cost !== null" [ngClass]="{ '!text-[#48BC25]': isHighlight }">

      {{ lineData.cost | currency: 'USD' : 'symbol' : '1.2-2' }}
    </span>
    <span class="price" *ngIf="lineData.cost === null" [ngClass]="{ '!text-[#48BC25]': isHighlight }">
      --
    </span>
  </li>
</ng-template>
